import React from "react";
// import { useEffect, useState } from "react";
import Carousel from "../Carousel/Carousel";
import UltimasNoticias from "../UltimasNoticias/UltimasNoticias";
import "./inicio.css";
import { titular } from "../../json/titular";
import { Link } from "react-router-dom";
import Indicadores from "../Indicadores/Indicadores";
// import Modal from "../Modal/Modal";

function Inicio() {
  // const [isModalVisible, setIsModalVisible] = useState(false);

  // useEffect(() => {
  //   window.scrollTo(0, 0);

  //   const timer = setTimeout(() => {
  //     setIsModalVisible(true);
  //   }, 1000); // 2000ms = 2 seconds

  //   // Clean up the timer if the component is unmounted before the 2 seconds
  //   return () => clearTimeout(timer);
  // }, []);

  // function handleClose() {
  //   setIsModalVisible(false);
  // }

  return (
    <>
      {/* <Modal show={isModalVisible} onClose={handleClose}>
        <div class="modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Concurso Nacional de Transparencia en Corto</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  Una disculpa, el sitio aun sigue en construcción, por lo que
                  puede que algunas de las funciones aun no esten disponibles.
                  Por tu comprensión, muchas gracias.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleClose}
                >
                  Entiendo
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      <Carousel search={true} name="banner-principal" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="secretaria_logo">
              <img
                className="secretaria_logo_img"
                src="/assets/logos/caasim-logo.svg"
                alt="Logo"
              />
            </div>
          </div>
          <div className="secretaria_funciones" id="functiones">
            {/* <h3 className="title">FUNCIONES</h3>
            <hr className="hr-gob" />
            <p>
              CAASIM es el ORGANISMO OPERADOR DE AGUA Y ALCANTARILLADO EN
              PACHUCA Y 14 MUNICIPIOS DEL ESTADO, es un Organismo
              Descentralizado de la Administración Pública del Estado, con
              personalidad jurídica y patrimonio propios. Su misión es
              incorporar acciones que busquen brindar eficiencia y calidad en la
              prestación del servicio del agua con responsabilidad social, la
              creación de sistemas modernos a través de mejoras continuas en el
              aprovechamiento de recursos naturales, garantizando así la
              satisfacción de las demandas presentes y futuras del agua (vital
              líquido).
            </p> */}
            <div className="btn_organigrama">
              <Link
                to="Transparencia/Rubros/DetalleRubro/02 Estructura"
                className="btn btn-primary"
              >
                Organigrama
              </Link>
              <Link
                to="Transparencia/Rubros/DetalleRubro/01 Normatividad"
                className="btn btn-primary"
              >
                Normatividad
              </Link>
            </div>
            <div className="secretaria-redes overlay-container">
              <a
                href="https://www.facebook.com/CAASIMcomunicacion/"
                aria-label="Facebook de CAASIM"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/culturadelagua_caasim/"
                aria-label="Instagram de CAASIM"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://twitter.com/CAASIM"
                aria-label="Twitter de CAASIM"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-twitter"></i>
              </a>
              <a
                href="https://www.tiktok.com/@gotintita"
                aria-label="Tiktok de CAASIM"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-tiktok"></i>
              </a>
            </div>
          </div>
          <Indicadores />
        </div>
        <UltimasNoticias />
        <div className="row">
          <div className="col-12">
            <div className="notes_title">
              <h3 className="title">TITULAR DE LA DEPENDENCIA</h3>
              <hr className="hr-gob" />
            </div>
            <div className="section_notes">
              {titular.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="sec_photo">
                    <div
                      className="img_secretario"
                      style={{ backgroundImage: `url("${item.imagen}")` }}
                    ></div>
                  </div>
                  <div className="sec_container">
                  <div className="sec_name">
                      <h3>Juan Evel Chávez Trovamala</h3>
                    </div>
                    <div className="sec_name">
                      <h3>{item.nombre}</h3>
                    </div>
                    <div className="sec_function">
                      {/* <h4>Función del Director</h4> */}
                      {/* <ul>
                        {item.funcion.split("\n").map((text, index) => (
                          <React.Fragment key={index}>
                            <li>{text}</li>
                          </React.Fragment>
                        ))}
                      </ul> */}
                      {/* <div className="btn_agenda">
                        <Link to="Agenda">Conoce la agenda del Director</Link>
                      </div> */}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="section_map">
              <div className="map_name">
                <h3 className="title">UBICACIÓN</h3>
                <hr className="hr-gob" />
              </div>
              <iframe
                title="Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59952.203472944195!2d-98.82372736930846!3d20.0917552499065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d109fa8806cdf1%3A0x7ad411ae0c78d5d3!2sCAASIM!5e0!3m2!1sen!2smx!4v1686780283789!5m2!1sen!2smx"
                width="100%"
                height="300"
                style={{ border: "none", marginBottom: 50 }}
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Inicio;
