import React from "react";
import Card from "../Card/Card";

const data = [
  {
    document: "Acuerdo",
    year: '2021',
    title: 'Acuerdo que Modifica la Integracion del CEPCI 2021',
    link: '/CAASIM/comite-de-etica/Acuerdo que Modifica la Integracion del CEPCI 2021.pdf'
  },
  {
    document: "Acuerdo",
    year: '2018',
    title: 'Acuerdo de Creación de CEPCI 2018',
    link: '/CAASIM/comite-de-etica/Acuerdo de Creación de CEPCI 2018.pdf'
  },
];

function ComiteDeEtica() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title text-uppercase">Comité de Ética</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
        {data.map((item, index) => (
          <Card
            key={index}
            document={item.document}
            title={item.title}
            href={item.link}
            year={item.year}
            type={"PDF"}
          />
        ))}
      </div>
    </div>
  );
}

export default ComiteDeEtica;
