export const links = [
  {
    nombre: "Inicio",
    link: "/",
  },
  {
    nombre: "¿Quiénes somos?",
    link: "/QuienesSomos",
  },
  {
    nombre: "Servicios CAASIM",
    link: "/Servicios",
  },
  {
    nombre: "Transparencia",
    link: "/Transparencia",
  },
];

