import React from "react";
import "./quienesSomos.css";
import { areas } from "../../json/areas";
import { sections } from "../../json/sectionesQuienesSomos";

function QuienesSomos() {
  const [selectedArea, setSelectedArea] = React.useState(null); // [0] is the default value

  return (
    <div className="container pt-4 pb-5">
      {sections.map((section, index) => (
        <SectionTitle key={index} title={section.title} text={section.text} />
      ))}
      <div className="secretaria_funciones mb-4">
        <h3 className="title">ÁREAS QUE LO CONFORMAN</h3>
        <hr className="hr-gob" />
        <div className="buttons-container">
          {areas.map((item, index) => (
            <BotonDireccion
              key={index}
              nombre={item.nombre}
              onClick={() => setSelectedArea(item)}
              disabled={true}
            />
          ))}
        </div>
        {selectedArea && (
          <>
            {selectedArea.nombre === "S.U.T.S.C.A.A.S.I.M.E.H." ? (
              <SUTSCAASIMEHComponent />
            ) : (
              <>
                {selectedArea.descripcion && (
                  <SectionTitle
                    title={selectedArea.nombre}
                    text={selectedArea.descripcion}
                  />
                )}
                {selectedArea.funciones && (
                  <SectionTitle
                    title="Funciones"
                    text={selectedArea.funciones}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function SUTSCAASIMEHComponent() {
  return (
    <div>
      <div className="secretaria_funciones">
        <h3 className="title">
          Sindicato Único De Trabajadores Al Servicio De La Comisión De Agua Y
          Alcantarillado De Sistemas Intermunicipales Del Estado De Hidalgo
        </h3>
        <hr className="hr-gob" />
        <h3 className="my-title text-center">
          <span className="d-block mb-4">Visita la pagina oficial del S.U.T.S.C.A.A.S.I.M.E.H. ⬇️</span>
          <a href="https://sutscaasimeh.org.mx" className="btn btn-primary" target="_blank" rel="noreferrer">sutscaasimeh.org.mx</a>
        </h3>
      </div>
    </div>
  );
}

function BotonDireccion({ nombre, onClick, disabled }) {
  return (
    <button type="button" className="btn btn-primary" onClick={onClick} disabled={disabled}>
      {nombre}
    </button>
  );
}

function SectionTitle({ title, text }) {
  return (
    <div className="secretaria_funciones">
      <h3 className="title">{title}</h3>
      <hr className="hr-gob" />
      {title === "Funciones" ? (
        <ul className="quienes-somos-ul">
          {text.split("\n").map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ) : (
        <p>{text}</p>
      )}
    </div>
  );
}

export default QuienesSomos;
