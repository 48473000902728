import React, { useEffect } from "react";
import { rubros } from "../../json/rubros";
import "./rubros.css";
import { Link } from "react-router-dom";

const Rubros = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">Articulo 69 Ley de Transparencia (48 Rubros)</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
        {rubros.map((item, index) => ( 
          <Link
            key={index}
            to={item.url ? item.url : "DetalleRubro/" + item.nombre}
            className="col-md-4 col-sm-6 col-12 rubro_enlace"
          >
            <div className="rubro_container">
              <div className="rubro_icono">
                <img
                  src={
                    "http://cdn.hidalgo.gob.mx/plantilla_secretarial/Rubros/PNG/" +
                    item.icono
                  }
                  alt="Rubro Logo"
                />
              </div>
              <h3>{item.nombre}</h3>
            </div>
          </Link>
        ))}
        <Link className="rubros_historicos" to="RubrosHistoricos">
          Información Ejercicios Anteriores Art. 69 (48 Fracciones)
        </Link>
      </div>
    </div>
  );
};

export default Rubros;
