import React from "react";
import "./modal.css";

function Modal({ show, children, onClose }) {
  if (!show) {
    return null;
  }

  return <div className="overlay">{children}</div>;
}

export default Modal;
