import "./card.css";

const Card = ({ title, href, document, year, description, type }) => (
  <div className="col-md-3 col-sm-6 col-12 hover-card ">
    <a href={href} target="_blank" rel="noreferrer">
      <div className="card_container_historicos card-card">
        <div className="card_anio">
          <h5>{document}</h5>
          {description && <h6>{description}</h6>}
          <div className="card_trimestre">
            <p>{title}</p>
            <div className="d-flex align-items-center justify-content-between">
              {year && (
                <p className="mb-0 badge rounded-pill bg-year text-white">
                  {year}
                </p>
              )}
              <div className="ml-auto">
                <span className="badge bg-year span-pdf">{type}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
);

export default Card;
