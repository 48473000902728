import './bajaDeUsuario.css';

const BajaDeUsuario = () => {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">Baja de Usuario</h3>
        <hr className="hr-gob" />
      </div>
      <div>
        <p>
          A continuación, te describiré los pasos a seguir para dar de baja tu cuenta de usuario en los servicios en línea de CAASIM.
        </p>

        <p>
          <strong>1. Accede a tu cuenta:</strong> Inicia sesión en la cuenta que deseas dar de baja. En la página de <a className='font-weight-bold' href="https://servicioscaasim.hidalgo.gob.mx/">Servicios en Línea CAASIM</a> o en tu aplicación móvil iOS o Android. Asegúrate de tener acceso a la dirección de correo electrónico o al número de teléfono asociados a esa cuenta.
        </p>

        <div className="tutorial-image-container py-3">
          <img src="/CAASIM/tutoriales/baja-de-usuario/AppCAASIM.jpg" alt="Iniciar Sesión" />
          <img src="/CAASIM/tutoriales/baja-de-usuario/ServiciosEnLinea.jpg" alt="Servicios en Línea" />
        </div>

        <p>
          <strong>2. Clic en Configuración de Usuario:</strong> Busca la sección de "Dar de baja mi cuenta". Aquí encontrarás un botón llamado "confirmación de baja". (Puedes escribir un comentario del motivo para dar de baja tu cuenta, esto es opcional).
        </p>

        <div className="tutorial-image-container py-3">
          <img src="/CAASIM/tutoriales/baja-de-usuario/ServLinEliminar.jpg" alt="Eliminar cuenta" />
          <img src="/CAASIM/tutoriales/baja-de-usuario/AppCasEliminar.jpg" alt="Eliminar cuenta" />
        </div>

        <p>
          <strong>3. Confirma la baja:</strong> Una vez seleccionado el botón de "confirmación de baja", se te mostrará un mensaje de confirmación. Selecciona "Aceptar" para confirmar la baja de tu cuenta.
        </p>

        <div className='tutorial-image-container-1 pt-1 pb-4'>
          <img src="/CAASIM/tutoriales/baja-de-usuario/deleteAccount.jpg" alt="Borrar Cuenta" />
        </div>

        <p>
          <strong>4. Revisa tu cuenta:</strong> Después de dar de baja la cuenta, verifica que ya no puedas acceder a ella intentando iniciar sesión. Si no puedes iniciar sesión, la cuenta se ha eliminado correctamente.
        </p>
      </div>
    </div>
  );
};

export default BajaDeUsuario;
