export const staticNoticias = [
  // Veda News
  // {
  //   imagen: "/assets/imgs/carousel-imgs/desktop/banner_veda.png",
  //   url: "",
  //   titulo: "",
  //   fecha: "",
  // },
  {
    imagen: "/assets/imgs/carousel-imgs/desktop/transparencia2024.png",
    url: "https://s-contraloria.hidalgo.gob.mx/descargables/Convocatoria%20Transparencia%20en%20Corto%202024%20Modificado.pdf",
    titulo:
      '"¡Participa en el Concurso Nacional "Transparencia En Corto 2024"!" ¡Click aqui para consulta las bases y participar! 👇👇👇',
    fecha: "",
  },
];
