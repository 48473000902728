export const titular = [
  {
    // nombre: "Funciones del Titular de la Dependencia",
    // nombre: "Juan Evel Chávez Trovamala",
    imagen: "/assets/imgs/perfil_profile/square_logo.png",
    funcion:
      "",
  },
];

// Cumplir con la representación legal del Organismo. \nProponer sistemas eficientes para la administración de personal, recursos financieros y materiales. \nProponer sistemas eficientes de registro, control y evaluación para alcanzar las metas y objetivos institucionales. \nAvalar los métodos que permita el óptimo aprovechamiento de los bienes muebles e inmuebles del organismo.
