import React, { useState, useEffect } from "react";
import { staticNoticias } from "../../json/ultimasNoticias";
import "./ultimasnoticias.css";

const API_ENDPOINT = "https://comunicacion-a.hidalgo.gob.mx/traer/notas";
const DETAIL_URL = "https://comunicacion-a.hidalgo.gob.mx/detalles/";

const CAROUSEL_ACTIVE = "carousel-item active";
const CAROUSEL_ITEM = "carousel-item";

const fetchNoticias = async () => {
  try {
    const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    return data.success.notas;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const UltimasNoticias = () => {
  const [noticias, setNoticias] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchNoticias();
      setNoticias(result);
      setLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div id="ultimas_noticias">
      <div className="row">
        <div className="col-12">
          <div className="notice_section">
            <div className="notice_title">
              <h3 className="title">ÚLTIMAS NOTICIAS</h3>
              <hr className="hr-gob" />
            </div>
            <div className="notice_content">
              <div className="col-sm-8 col-12">
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    {staticNoticias.map((item, index) => (
                      <div
                        key={item.id || index} 
                        className={index === 0 ? CAROUSEL_ACTIVE : CAROUSEL_ITEM}
                      >
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <div
                            className="img-carousel d-block w-100"
                            style={{ backgroundImage: `url("${item.imagen}")` }}
                            alt={`Slide of ${item.titulo}`}
                          >
                            <div className="title_img_carousel">
                              <h6>{item.titulo}</h6>
                              <p>{item.fecha}</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="col-md-4 col-12 d-none d-block-md">
                <div className="card_container">
                  {noticias.map((item) => (
                    <div key={item.id || item.title} className="card">
                      <div className="card-body">
                        <p className="card_date">
                          {new Date(item.date).toLocaleString()}
                        </p>
                        <h5 className="card-title">{item.secretaria}</h5>
                        <h4 className="card-subtitle mb-2 text-muted">
                          {item.title}
                        </h4>
                        <p className="card-text">{item.descripcion}</p>
                        <a
                          href={DETAIL_URL + item.id}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Leer completo
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row pt-4">
        <div className="col">
          <a href="/assets/imgs/ultimas-noticias/Convocatoria_2023.pdf" target="_blank">
            <img
              src="/assets/imgs/Banner 2_TC_2023.jpeg"
              alt="Concurso de transparencia"
              className="img-fluid"
            />
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default UltimasNoticias;
