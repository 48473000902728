import React from "react";
import "./programaAnualDFBM.css";

function ProgramaAnualDFBM() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">
          PROGRAMA ANUAL DE DISPOSICION FINAL DE BIENES MUEBLES
        </h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
      <div className="col-md-6 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2024</h5>
              <div className="card_trimestre">
                <p>
                  Programa Anual de Disposición Final de Bienes Muebles
                  <span className="badge bg-secondary">
                    <a href="/CAASIM/programa-anual-dfbm/PROGRAMA ANUAL DE DISPOSICIÓN FINAL DE BIENES MUEBLES 2024.pdf" target="_blank" rel="noreferrer">
                      PDF
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2021</h5>
              <div className="card_trimestre">
                <p>
                  Programa Anual de Disposición Final de Bienes Muebles
                  <span className="badge bg-secondary">
                    <a href="/CAASIM/programa-anual-dfbm/PROGRAMA ANUAL DE DISPOSICIÓN FINAL DE BIENES MUEBLES.PDF" target="_blank" rel="noreferrer">
                      PDF
                    </a>
                  </span>
                </p>
              </div>
              <div className="card_trimestre">
                <p>
                  Modificación al Programa Anual de Disposición Final
                  <span className="badge bg-secondary">
                    <a href="/CAASIM/" target="_blank" rel="noreferrer">
                      PDF
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramaAnualDFBM;
