// Convocatorias 2024

export const convocatoria001 = [
  {
    nombre: "Convocatoria 01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/CONVOCATORIA 01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria002 = [
  {
    nombre: "Convocatoria 02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/CONVOCATORIA 02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria003 = [
  {
    nombre: "Convocatoria 03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024/CONVOCATORIA 03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n1 = [
  {
    nombre: "Bases N1",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N1/BASES EA-913005999-N1-2024 CARGO ADMINISTRATIVO POR LA PRESTACIÓN DEL SERVICIO DE DISPERSIÓN DE VALES DE DESPENSA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N1",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N1/JUNTA DE ACLARACIONES EA-913005999-N1-2024 CARGO ADMINISTRATIVO POR LA PRESTACIÓN DEL SERVICIO DE DISPERSIÓN DE VALES DE DESPENSA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N1",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N1/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N1-2024 CARGO ADMINISTRATIVO POR LA PRESTACIÓN DEL SERVICIO DE DISPERSIÓN DE VALES DE DESP.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N1",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N1/FALLO EA-913005999-N1-2024 CARGO ADMINISTRATIVO POR LA PRESTACIÓN DEL SERVICIO DE DISPERSIÓN DE VALES DE DESPENSA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n2 = [
  {
    nombre: "Bases N2",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N2/BASES EA-913005999-N2-2024 ALIMENTACION.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N2",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N2/JUNTA DE ACLARACIONES EA-913005999-N2-2024 ALIMENTACIÓN DE PERSONAS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N2",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N2/PRESENTACION Y APERTURA DE PROPOSICIONES  EA-913005999-N2-2024 ALIEMTANCION DE PERSONAS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N2",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N2/FALLO EA-913005999-N2-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n3 = [
  {
    nombre: "Bases N3",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N3/BASES EA-913005999-N3-2024 ARRENDAMIENTO DE MAQUINARIA, OTROS EQUIPOS Y HERRAMIENTAS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N3",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N3/JUNTA DE ACLARACIONES EA-913005999-N3-2024 ARRENDAMIENTO DE MAQUINARIA, OTROS EQUIPOS Y HERRAMIENTAS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N3",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N3/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N3-2024 ARRENDAMIENTO DE MAQUINARIA, OTROS EQUIPOS Y HERRAMIENTAS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N3",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024/N3/FALLO EA-913005999-N3-2024 ARRENDAMIENTO DE MAQUINARIA, OTROS EQUIPOS Y HERRAMIENTAS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n4 = [
  {
    nombre: "Bases N4",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N4/BASES EA-913005999-N4-2024 SERVICIO DE TRASLADO DE VALORES.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N4",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N4/JUNTA DE ACLARACIONES EA-913005999-N4-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N4",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N4/APERTURA TECNICA Y ECONOMICA EA-913005999-N4-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N4",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N4/FALLO EA-913005999-N4-2024 TRASLADO DE VALORES (2DO. PROCED.).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n5 = [
  {
    nombre: "Bases N5",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N5/BASES EA-913005999-N5-2024 SEGUROS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N5",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N5/JUNTA DE ACLARACIONES EA-913005999-N5-2024 SEGUROS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N5",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N5/APERTURA TECNICA Y ECONOMICA EA-913005999-N5-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N5",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 02-2024/N5/FALLO EA-913005999-N5-2024 SEGUROS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n6 = [
  {
    nombre: "Bases N6",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024/N6/BASES EA-913005999-N6-2024 SERVICIOS DE ACCESO DE INTERNET, REDES Y PROCESAMIENTO DE INFORMACIÓN.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N6",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024/N6/Junta de Aclaraciones EA-913005999-N6-2024 Servicios de Acceso de Internet, Redes y Procesamiento de Información.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N6",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024/N6/Acta de Presentación y Apertura de Proposiciones EA-913005999-N6-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N6",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024/N6/Acta de Fallo EA-913005999-N6-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoriaLOP001 = [
  {
    nombre: "Convocatoria 01-2024 OBRA",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CONVOCATORIA 01-2024 OBRA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop1 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/BASES CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
            // new: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
            // new: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
            // new: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "PLANO PROYECTO FINAL DETALLES CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/PLANO PROYECTO FINAL DETALLES CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
            // new: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "PLANO PROYECTO FINAL PGDA CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/PLANO PROYECTO FINAL PGDA CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
            // new: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "ACUERDO FE DE ERRATAS CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/ACUERDO FE DE ERRATAS CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
            // new: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre:
      "PRESENTACIÓN Y APERTURA DE PROPOSICIONES CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // publicationDate: "2024-22-04",
            // new: "www.google.com",
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/PRESENTACIÓN Y APERTURA DE PROPOSICIONES CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO CAASIM-HGO-L.O.P.-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // publicationDate: "2024-22-04",
            // new: "www.google.com",
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 01-2024 OBRA/CAASIM-HGO-L.O.P.-01-2024/FALLO CAASIM-HGO-L.O.P.-01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria004 = [
  {
    nombre: "Convocatoria 04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024/CONVOCATORIA 04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria005 = [
  {
    nombre: "Convocatoria 05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/CONVOCATORIA 05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n7 = [
  {
    nombre: "Bases N7",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024/N7/BASES EA-913005999-N7-2024 CAPACTACIÓN.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N7",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024/N7/JUNTA DE ACLARACIONES EA-913005999-N7-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N7",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024/N7/APERTURA TECNICA ECONOMICA EA-913005999-N7-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO N7",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024/N7/FALLO EA-913005999-N7-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n8 = [
  {
    nombre: "Bases N8",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N8/BASES EA-913005999-N8-2024 Combustibles, lubricantes y aditivos como materia prima.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N8",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N8/JUNTA DE ACLARACIONES EA-913005999-N8-2024 COMBUSTIBLES, LUBRICANTES Y ADITIVOS COMO MATERIA PRIMA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N8",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N8/Acta de Presentación y Apertura EA-913005999-N8-2024 Combustibles, Lubricantes y Aditivos como Materia Prima.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO N8",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N8/FALLO EA-913005999-N8-2023 COMBUSTIBLES, LUBRICANTES Y ADITIVOS COMO MATERIA PRIMA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n9 = [
  {
    nombre: "Bases N9",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N9/BASES DE LICITACIÓN EA-913005999-N9-2024 MATERIAL DE LIMPIEZA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N9",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N9/JUNTA DE ACLARACIONES EA-913005999-N9-2024 MATERIAL DE LIMPIEZA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N9",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N9/Acta de Presentación y Apertura EA-913005999-N9-2024 Material de Limpieza.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO DIFERIDO N9",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N9/FALLO DIFERIDO ESA-913005999-N9-2024 MATERIAL DE LIMPIEZA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO N9",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N9/FALLO  EA-913005999-N9-2024 MATERIAL DE LIMPIEZA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n10 = [
  {
    nombre: "Bases N10",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N10/BASES DE LICITACIÓN EA-913005999-N10-2024 SUSTANCIAS QUÍMICAS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N10",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N10/JUNTA DE ACLARACIONES EA-913005999-N10-2024 SUSTANCIAS QUÍMICAS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Continuación Junta de Aclaraciones N10",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N10/CONTINUACIÓN DE JUNTA DE ACLARACIONES EA-913005999-N10-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N10",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N10/Acta de Presentación y Apertura EA-913005999-N10-2024 Sustancias Químicas.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO N10",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N10/Fallo EA-913005999-N10-2024 Sustancias Químicas.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n11 = [
  {
    nombre: "Bases N11",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N11/BASES EA-913005999-N11-2024 GASTOS DE ORDEN SOCIAL.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N11",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N11/JUNTA DE ACLARACIONES EA-913005999-N11-2024 GASTOS DE ORDEN SOCIAL.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N11",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N11/Acta de Presentación y Apertura EA-913005999-N11-2024 Gastos de Orden Social y cultural.PDF",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "FALLO N11",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // ecel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 05-2024/N11/FALLO EA-913005999-N11-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria006 = [
  {
    nombre: "Convocatoria 06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/CONVOCATORIA 06-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n12 = [
  {
    nombre: "Bases N12",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N12/BASES DE LICITACIÓN EA-913005999-N12-2024 COMBUSTIBLES Y LUBRICANTES PARA VEHÍCULOS Y EQUIPOS TERRESTRES.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N12",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N12/JUNTA DE ACLARACIONES EA-913005999-N12-2024.pdf ",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N12",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N12/PRESENTACIÓN Y APERTURA DE PROPODICIONES EA-913005999-N12-2024 COMBUSTIBLES Y LUBRICANTES PARA VEHICULOS Y EQUIPOS TERRESTRES.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N12",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N12/FALLO EA-913005999-N12-2024 COMBUSTIBLES Y LUBRICANTES PARA VEHICULOS Y EQUIPO TERRESTRE.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n13 = [
  {
    nombre: "Bases N13",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N13/BASES DE LICITACIÓN EA-913005999-N13-2024 PRODUCTOS MINERALES NO METÁLICOS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N13",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N13/JUNTA DE ACLARACIONES EA-913005999-N13-2024 PRODUCTOS MINERALES NO METÁLICOS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N13",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N13/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N13-2024 PRODUCTOS MINERALES NO METÁLICOS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N13",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N13/FALLO EA-913005999-N13-2024 PRODUCTOS MINERALES NO METÁLICOS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n14 = [
  {
    nombre: "Bases N14",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N14/BASES DE LICITACIÓN EA-913005999-N14-2024 OTROS MATERIALES Y ARTICULOS DE CONSTRUCCION.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N14",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N14/JUNTA DE ACLARACIONES EA-913005999-N14-2024 OTROS MATERIALES Y ARTICULOS DE CONSTRUCCION Y REPARACIÓN.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N14",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N14/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N14-2024 OTROS MATERIALES Y ARTÍCULOS DE CONSTRUCCIÓN Y REPARACIÓN.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N14",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N14/FALLO EA-913005999-N14-2024 OTROS MATERIALES Y ARTICULOS DE CONSTRUCCIÓN Y REPARACIÓN.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n15 = [
  {
    nombre: "Bases N15",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N15/BASES DE LICITACIÓN EA-913005999-N15-2024 ADQUISICIÓN DE ADQUISICIÓN DE MONTACARGAS DE 3000KG.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N15",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N15/JUNTA DE ACLARACIONES EA-913005999-N15-2024 ADQUISICIÓN DE MONTACARGAS DE 3000KG.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N15",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N15/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N15-2024 ADQUISICIÓN DE MONTACARGAS DE 3000KG.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N15",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N15/FALLO EA-913005999-N15-2024 ADQUISICIÓN DE MONTACARGAS DE 3000KG.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n16 = [
  {
    nombre: "Bases N16",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N16/BASES DE LICITACIÓN EA-913005999-N16-2024 ADQ. DE MEDIDORES.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N16",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N16/JUNTA DE ACLARACIONES EA-913005999-N16-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N16",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N16/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N16-2024 ADQUISICIÓN DE MEDIDORES PARA EL SISTEMA COMERCIAL DE LA CAASIM.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N16",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N16/FALLO EA-913005999-N16-2024 ADQUISICIÓN DE MEDIDORES PARA EL SISTEMA COMERCIAL DE LA CAASIM.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n17 = [
  {
    nombre: "Bases 17",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N17/BASES DE LICITACIÓN EA-913005999-N17-2024 ADQUISICIÓN DE GRÚA TIPO HIAB.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N17",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N17/JUNTA DE ACLARACIONES EA-913005999-N17-2024 Adquisición de Grúa tipo HIAB.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N17",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N17/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N17-2024 ADQUISICIÓN DE GRUA TIPO Tipo HIAB.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N17",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N17/FALLO EA-913005999-N17-2024 ADQUISICIÓN DE GRÚA TIPO HIAB.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n18 = [
  {
    nombre: "Bases N18",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N18/BASES DE LICITACIÓN EA-913005999-N18-2024 ADQUISICIÓN DE DOS CAMIONES CISTERNA .pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N18",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N18/JUNTA DE ACLARACIONES EA-913005999-N18-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N18",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N18/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N18-2024 ADQUISICIÓN DE DOS CAMIONES CISTERNA DE 103 DE CAPACIDAD.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N18",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N18/FALLO EA-913005999-N18-2024 ADQUISICIÓN DE DOS CAMIONES CISTERNA DE 10 M3 DE CAPACIDAD.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n19 = [
  {
    nombre: "Bases N19",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N19/BASES DE LICITACIÓN EA-913005999-N19-2024 ADQUISICIÓN DE EQUIPO SEPTICO HIDRONEUMATICO PARA AGUAS NEGRAS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N19",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N19/JUNTA DE ACLARACIONES EA-913005999-N19-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N19",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N19/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N19-2024 ADQUISICIÓN DE EQUIPO SÉPTICO HIDRONEUMÁTICO PARA AGUAS NEGRAS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N19",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024/N19/FALLO EA-913005999-N19-2024 ADQUISICIÓN DE EQUIPO SÉPTICO HIDRONEUMÁTICO PARA AGUAS NEGRAS.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria007 = [
  {
    nombre: "Convocatoria 07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024/CONVOCATORIA 07-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n20 = [
  {
    nombre: "Bases N20",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024/N20/BASES DE LICITACIÓN EA-913005999-N20-2024 MANTENIMIENTO DE MAQUINARIA Y EQUIPO.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N20",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024/N20/JUNTA DE ACLARACIONES EA-913005999-N20-2024 MANTENIMIENTO DE MAQUINARIA Y EQUIPO.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N20",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024/N20/PRESENTACIÓN Y APERTURA DE PROPOSICIONES EA-913005999-N20-2024 MANTENIMIENTO DE MAQUINARIA Y EQUIPO.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo Diferido N20",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024/N20/FALLO DIFERIDO EA-913005999-N20-2024 MANTENIMIENTO DE MAQUINARIA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N20",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 07-2024/N20/FALLO EA-913005999-N20-2024 MANTENIMIENTO DE MAQUINARIA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoriaMultiple002 = [
  {
    nombre: "CONVOCATORIA MULTIPLE 02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CONVOCATORIA MULTIPLE 02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop2 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-02-2024/BASES CAASIM-HGO-L.O.P.-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-L.O.P.-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-02-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-02-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-02-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-02-2024001.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura CAASIM-HGO-L.O.P.-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-02-2024/PRESENTACIÓN Y APERTURA DE PROPOSICIONES CAASIM-HGO-L.O.P.-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo CAASIM-HGO-L.O.P.-02-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-02-2024/FALLO CAASIM-HGO-L.O.P.-02-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop3 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-03-2024/BASES CAASIM-HGO-L.O.P.-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-L.O.P.-03-2024 ",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-03-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-03-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-03-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-03-2024001.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura CAASIM-HGO-L.O.P.-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-03-2024/PRESENTACIÓN Y APERTURA DE PROPOSICIONES CAASIM-HGO-L.O.P.-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo CAASIM-HGO-L.O.P.-03-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-03-2024/FALLO CAASIM-HGO-L.O.P.-03-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop4 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-04-2024/BASES CAASIM-HGO-L.O.P.-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-L.O.P.-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-04-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-04-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-04-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-04-2024001.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura CAASIM-HGO-L.O.P.-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-04-2024/PRESENTACIÓN Y APERTURA DE PROPOSICIONES CAASIM-HGO-L.O.P.-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo CAASIM-HGO-L.O.P.-04-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 02-2024/CAASIM-HGO-L.O.P.-04-2024/FALLO CAASIM-HGO-L.O.P.-04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria03Obra = [
  {
    nombre: "CONVOCATORIA 03-2024 OBRA",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CONVOCATORIA 03-2024 OBRA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop5 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CAASIM-HGO-L.O.P.-05-2024/BASES CAASIM-HGO-L.O.P.-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-L.O.P.-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CAASIM-HGO-L.O.P.-05-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CAASIM-HGO-L.O.P.-05-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CAASIM-HGO-L.O.P.-05-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-05-20001.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura CAASIM-HGO-L.O.P.-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CAASIM-HGO-L.O.P.-05-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-L.O.P.-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo CAASIM-HGO-L.O.P.-05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 03-2024 OBRA/CAASIM-HGO-L.O.P.-05-2024/FALLO CAASIM-HGO-L.O.P.-05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria008 = [
  {
    nombre: "CONVOCATORIA 08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/CONVOCATORIA 08-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n22 = [
  {
    nombre: "Bases N22",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N22/BASES DE LICITACIÓN EA-913005999-N22-2024 PRODUCTOS MINERALES NO METÁLICOS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N22",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N22/JUNTA DE ACLARACIONES EA-913005999-N22-2024 PRODUCTOS MINERALES NO METÁLICOS (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N22",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N22/Presentación y Apertura de Proposiciones N22.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N22",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N22/FALLO N22.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n24 = [
  {
    nombre: "Bases N24",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N24/BASES DE LICITACIÓN EA-913005999-N24-2024 MANTENIMIENTO DE MAQUINARIA Y EQUIPO (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N24",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N24/JUNTA DE ACLARACIONES EA-913005999-N24-2024 MANTENIMIENTO DE MAQUINARIA Y EQUIPO (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N24",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N24/Presentación y Apertura de Proposiciones N24.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N24",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N24/FALLO N24.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n25 = [
  {
    nombre: "Bases N25",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N25/BASES DE LICITACIÓN EA-913005999-N25-2024 MANTENIMIENTO E INSTALACIÓN DE EQUIPO Y HERRAMIENTAS PARA SUMINISTRO DE AGUA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N25",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N25/JUNTA DE ACLARACIONES EA-913005999-N25-2024 MATENIMIENTO E INSTALACIÓN DE EQUIPOSY HERRAMIENTAS PARA SUMINISTRO DE AGUA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N25",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N25/Presentación y Apertura de Proposiciones N25.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N25",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 08-2024/N25/FALLO N25.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria009 = [
  {
    nombre: "CONVOCATORIA 09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/CONVOCATORIA 09-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n21 = [
  {
    nombre: "Bases N21",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N21/BASES DE LICITACIÓN EA-913005999-N21-2024 ADQUISICION DE TRANSFORMADORES PARA SUSTITUCION.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N21",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N21/JUNTA DE ACLARACIONES EA-913005999-N21-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N21",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N21/Presentación y Apertura de Proposiciones N21.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N21",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N21/FALLO EA-913005999-N21-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n26 = [
  {
    nombre: "Bases N26",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N26/BASES DE LICITACIÓN EA-913005999-N26-2024 EQUIPAMIENTO DE LA PTAR MATILDE.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N26",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N26/JUNTA DE ACLARACIONES EA-913005999-N26-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N26",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N26/Presentación y Apertura de Proposiciones N26.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N26",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 09-2024/N26/FALLO EA-913005999-N26-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const convocatoria010 = [
  {
    nombre: "CONVOCATORIA 10-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 10-2024/CONVOCATORIA 10-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n27 = [
  {
    nombre: "Bases N27",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 10-2024/N27/BASES DE LICITACIÓN EA-913005999-N27-2024 EQUIPAMIENTOELECTROMECÁNICO PARA PLANTAS DE BOMBEO EN LA ZONA DE INFLUENCIA DE LA CAASIM.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N27",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 10-2024/N27/JUNTA DE ACLARACIONES EA-913005999-N27-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N27",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 10-2024/N27/EA-913005999-N27-2024 PRESENTACIÓN Y APERTURA DE PROPOSICIONES EQUIPAMENTO ELECTROMECÁNICO PARA PLANTAS DE BOMBEO EN LA ZONA DE INFLUENCIA DE LA CAASIM.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N27",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 10-2024/N27/FALLO EA-913005999-N27-2024 EQUIPAMENTO ELECTROMECÁNICO PARA PLANTAS DE BOMBEO EN LA ZONA DE INFLUENCIA DE LA CAASIM.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];
//

export const convocatoria04Obra = [
  {
    nombre: "CONVOCATORIA 04-2024 OBRA",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024 OBRA/CONVOCATORIA 04-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop6 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024 OBRA/CAASIM-HGO-L.O.P.-06-2024/BASES CAASIM-HGO-L.O.P.-06-2024 (SEGUNDO PROCEDIMIENTO)_.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catálogo Mudo CAASIM-HGO-L.O.P.-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024 OBRA/CAASIM-HGO-L.O.P.-06-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-06-2024 (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024 OBRA/CAASIM-HGO-L.O.P.-06-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-06-2024 (SEGUNDO PROCEDIMIENTO).pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-06-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024 OBRA/CAASIM-HGO-L.O.P.-06-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P-06-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Bases CAASIM-HGO-L.O.P.-06-2024",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           // excel: "www.google.com",
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 04-2024 OBRA/CAASIM-HGO-L.O.P.-06-2024/",
  //           // word: "www.google.com",
  //         },
  //       ],
  //     },
  //   ],
  // },
  //
];

export const convocatoria011 = [
  {
    nombre: "CONVOCATORIA 11-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/CONVOCATORIA 11-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n28 = [
  {
    nombre: "Bases N28",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N28/BASES EA-913005999-N28-2024 CEMENTO Y PRODUCTOS DE CONCRETO.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N28",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N28/JUNTA DE ACLARACIONES EA-913005999-N28-2024 CEMENTO Y PRODUCTOS DE CONCRETO.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N28",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N28/Presentación y Apertura de Proposiciones N28.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N28",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N28/FALLO EA-913005999-N28-2024 CEMENTO Y PRODUCTOS DE CONCRETO.pdf",
          },
        ],
      },
    ],
  },
];

export const n29 = [
  {
    nombre: "Bases N29",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N29/BASES DE LICITACIÓN EA-913005999-N29.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N29",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N29/JUNTA DE ACLARACIONES EA-913005999-N29-2024 SEGUROS.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N29",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N29/ACTA DE APERTURA DE PROPOSICIONES EA-913005999-N29-2024 SEGUROS_001.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Fallo N29",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N29/FALLO EA-913005999-N29-2024 SEGUROS.pdf",
          },
        ],
      },
    ],
  },
];

export const n30 = [
  {
    nombre: "Bases N30",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N30/BASES EA-913005999-N30-2024 SERVICIO DE LIMPIEZA Y MANEJO DE DESECHOS.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N30",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N30/JUNTA DE ACLARACIONES EA-913005999-N30-2024 SERVICIO DE LIMPIEZA Y MANEJO DE DESECHOS.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones N30",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N30/Presentación y Apertura de Proposiciones N30.pdf",
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "Fallo N30",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 11-2024/N30/FALLO EA-913005999-N30-2024.pdf",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const convocatoriaMultiple005 = [
  {
    nombre: "CONVOCATORIA MULTIPLE 05-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CONVOCATORIA MULTIPLE 05-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop7 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-07-2024/BASES CAASIM-HGO-L.O.P.-07-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-07-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-07-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catálogo Mudo CAASIM-HGO-L.O.P.-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-07-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-07-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-07-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-07-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-L.O.P.-07-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-07-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-L.O.P.-07-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const lop8 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-08-2024/BASES CAASIM-HGO-L.O.P.-08-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-08-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-08-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catálogo Mudo CAASIM-HGO-L.O.P.-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-08-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-08-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-08-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-08-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura de Proposiciones CAASIM-HGO-L.O.P.-08-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA MULTIPLE 05-2024/CAASIM-HGO-L.O.P.-08-2024/PRESENTACIÓN Y APERTURA CAASIM-HGO-L.O.P.-08-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const convocatoria012 = [
  {
    nombre: "CONVOCATORIA 12-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/CONVOCATORIA 12-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const n31 = [
  {
    nombre: "Bases N31",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/BASES EA-913005999-N31-2024 FORMAS VALORADAS.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones N31",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/JUNTA DE ACLARACIONES EA-913005999-N31-2024 FORMAS VALORADAS.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 1 Formato de Acta Circunstanciada",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 1 FORMATO DE ACTA CIRCUNTANCIADA.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 3 Calcomania 3er Trimestre 2024.jpg",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 1 FORMATO DE ACTA CIRCUNTANCIADA.pdf",
            jpg: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 3 CALCOMANIA 3ER TRIMESTRE 2024.jpg",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 5 Etiqueta Muestra 11X10",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            word: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 5 ETIQUETA MUESTRA 11X10.docx",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 6 Formato de Sello Adherible de Suspendido",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 6 FORMATO DE  SELLO ADHERIBLE DE SUSPENDIDO.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 7 Formato de Contrato de Prestacion de Servicios",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 7 FORMATO DE CONTRATO DE PRESTACIÓN DE SERVICIOS.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 9 Calcomania 4to Trimestre 2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            jpg: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 9 CALCOMANIA 4TO TRIMESTRE 2024.jpg",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 10 Formato de Informe de Instalacion o Sustitucion",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 10 fORMATO DE INFORME DE INSTALACIÓN O SUSTITUCIÓN.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 11 Formato de Solicitud de Cambio al Padron de Usuarios",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 11 FORMATO DE SOLICITUD DE CAMBIO AL PADRON DE USUARIOS.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 13 Calcomania 1er Trimestre 2025",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            jpg: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 13 CALCOMANIA 1ER TRIMESTRE 2025.jpg",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 14 Anverso Formato de Hoja de Recibo de Agua Potable",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 14 ANVERSO FORMATO DE HOJA DE RECIBO DE AGUA POTABLE.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 14 Reverso Formato de Hoja de Recibo de Agua Potable",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 14 REVERSO FORMATO DE HOJA DE RECIBO DE AGUA POTABLE.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre:
      "Partido 15 Formato de Informe de Supervision de Instalacion y Verificacion",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 15 FORMATO DE INFORME DE SUPERVISIÓN Y VERIFICACIÓN.pdf",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 16 Calcomania 2do Trimestre 2025",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            jpg: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 16 CALCOMANIA 2DO TRIMESTRE 2025.jpg",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 17 Formato de Reporte de Usuario",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            excel:
              "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 17 FORMATO REPORTE DE USUARIO.xls",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 18 Formato de Verificacion Pipas Agua Potable",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            excel:
              "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 18 FORMATO VERIFICACIÓN PIPAS AGUA POTABLE.xls",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partida 19 Formato Prefactura",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            excel:
              "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 19 FORMATO PREFACTURA.xls",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Partido 20 Etiqueta Muestra 7X8",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/PARTIDA 20  ETIQUETA MUESTRA 7X8.docx",
            // new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Acta de Apertura N31",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/ACTA DE APERTURA  EA-913005999-N31-2024 FORMAS VALORADAS.pdf",
            new: true,
          },
        ],
      },
    ],
  },
  // {
  //   nombre: "",
  //   documentos: [
  //     {
  //       nombre: "Archivo",
  //       contenido: [
  //         {
  //           pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 12-2024/N31/",
  //           new: true,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // 
];

export const convocatoria06Obra = [
  {
    nombre: "CONVOCATORIA 06-2024 OBRA",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024 OBRA/CONVOCATORIA 06-2024 OBRA.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];

export const lop9 = [
  {
    nombre: "Bases CAASIM-HGO-L.O.P.-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024 OBRA/CAASIM-HGO-L.O.P.-09-2024/BASES CAASIM-HGO-L.O.P.-09-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-L.O.P.-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 06-2024 OBRA/CAASIM-HGO-L.O.P.-09-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-L.O.P.-09-2024.pdf",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catálogo Mudo CAASIM-HGO-L.O.P.-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024//CONVOCATORIA 06-2024 OBRA/CAASIM-HGO-L.O.P.-09-2024/CATALOGO MUDO CAASIM-HGO-L.O.P.-09-2024.pdf",
            new: true,
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-L.O.P.-09-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024//CONVOCATORIA 06-2024 OBRA/CAASIM-HGO-L.O.P.-09-2024/JUNTA DE ACLARACIONES CAASIM-HGO-L.O.P.-09-2024.pdf",
            new: true,
          },
        ],
      },
    ],
  },
  // 
];

export const convocatoria013 = [
  {
    nombre: "CONVOCATORIA 13-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 13-2024/CONVOCATORIA 13-2024.pdf",
          },
        ],
      },
    ],
  },
];

export const n32 = [
  {
    nombre: "Bases N32",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 13-2024/N32/BASES EA-913005999-N32-2024 CEMENTO Y PRODUCTOS DE CONCRETO (SEGUNDO PROCEDIMIENTO).pdf",
          },
        ],
      },
    ],
  },
];

export const n33 = [
  {
    nombre: "Bases N33",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            pdf: "/CAASIM/licitaciones/2024/CONVOCATORIA 13-2024/N33/BASES EA-913005999-N33-2024 SERVICIO DE LIMPIEZA Y MANEJO DE DESECHOS SEGUNDO PROCEDIMIENTO.docx.pdf",
          },
        ],
      },
    ],
  },
];