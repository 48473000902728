import React from "react";

function Articulos() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title text-uppercase">
          Ley Estatal de Agua y Alcantarillado para el Estado de Hidalgo
        </h3>
        <hr className="hr-gob" />
        <div>
          <p className="message-articles">
            <strong>Artículo 115.-</strong> Es obligatoria la instalación de
            aparatos medidores para la verificación del consumo de agua, estos
            deben instalarse en lugares donde el personal del prestador del
            servicio pueda efectuar las lecturas sin necesidad de introducirse
            al predio, llevar a cabo las pruebas de funcionamiento de los
            aparatos, realizar las suspensiones del servicio y otras actividades
            que sean necesarias en la prestación del servicio. Los usuarios,
            bajo su estricta responsabilidad, cuidarán que no se deterioren los
            medidores. Cuando las tomas y medidores no estén al acceso del
            personal, los consumos se estimarán de acuerdo con esta Ley y el
            servicio se suspenderá desde pozo de banqueta o red de distribución,
            según sea el caso, hasta que se dé cumplimiento a lo anterior.
          </p>
          <p className="message-articles">
            <strong>Artículo 145.-</strong> La Procuraduría o los prestadores de
            los servicios a excepción de los concesionarios, podrán ordenar la
            práctica de visitas de investigación, supervisión, vigilancia y
            verificación de los servicios por medio del personal autorizado,
            debiendo hacerlo por escrito motivando y fundamentando el acto a
            realizar, observando el procedimiento previsto en la Ley para la
            Protección al Ambiente del Estado de Hidalgo.
          </p>
          <p className="message-articles">
            <strong>ARTICULO 42.-</strong> Con el propósito de facilitar la
            lectura de consumo de agua potable, el prestador del servicio,
            instalará la toma correspondiente en los predios, giros mercantiles
            o industrias, de tal forma que el aparato medidor quede ubicado en
            forma visible en el límite exterior del inmueble.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Articulos;
