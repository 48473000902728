export const servicios = [
  {
    nombre: "CAASIM en Linea",
    icono: "fa fa-users fa-2x",
    url: "https://servicioscaasim.hidalgo.gob.mx/",
  },
  {
    nombre: "Facturación Electrónica",
    icono: "fa fa-file-pdf-o fa-2x",
    url: "https://servicioscaasim.hidalgo.gob.mx/FacturacionElectronicaCaasim/",
  },
  {
    nombre: "Busqueda de Trámites",
    icono: "fa fa-search fa-2x",
    url: "https://ruts.hidalgo.gob.mx/",
  },
];
