export const transparencia_item = [
  {
    id: 1,
    icono: "fa fa-files-o fa-2x",
    nombre: "Articulo 69 Ley de Transparencia (48 Rubros)",
    url: "Rubros",
    target: "_self",
  },
  {
    id: 2,
    icono: "fa fa-file-text fa-2x",
    nombre: "Informe de Gasto Federalizado SFU",
    url: "GastoFederalizado",
    target: "_self",
  },
  {
    id: 3,
    icono: "fa fa-clipboard fa-2x",
    nombre: "Licitaciones",
    url: "Licitaciones",
    target: "_self",
  },
  {
    id: 4,
    icono: "fa fa-files-o fa-2x",
    nombre: "Contraloria Social",
    url: "ContraloriaSocial",
    target: "_self",
  },
  // {
  //   id: 5,
  //   icono: "fa fa-envelope-open fa-2x",
  //   nombre: "Convocatoria Protocolo Cero",
  //   url: "/CAASIM/convocatoria-protocolo-0/CONVOCATORIA - PROTOCOLO CERO.pdf",
  //   target: "_blank",
  // },
  {
    id: 6,
    icono: "fa fa-folder fa-2x",
    nombre: "Catalogo de Inconsistencias en Recibo",
    url: "InconsistenciasRecibo",
    target: "_self",
  },
  {
    id: 7,
    icono: "fa fa-tasks fa-2x",
    nombre: "Programa Institucional de Desarrollo",
    url: "ProgramaInstitucionalDeDesarrollo",
    target: "_self",
  },
  {
    id: 8,
    icono: "fa fa-map-marker fa-2x",
    nombre: "Municipios y Sucursales",
    url: "MunicipiosYSucursales",
    target: "_self",
  },
  {
    id: 9,
    icono: "fa fa-money fa-2x",
    nombre: "Cuotas y Tarifas",
    url: "Tarifas",
    target: "_self",
  },
  {
    id: 10,
    icono: "fa fa-money fa-2x",
    nombre: "Armonizacion Contable",
    url: "ArmonizacionContable",
    target: "_self",
  },
  {
    id: 11,
    icono: "fa fa-files-o fa-2x",
    nombre: "Normatividad Relacionada",
    url: "/CAASIM/48-rubros/actual/htm/a69_f01CAASIM.htm",
    target: "_blank",
  },
  {
    id: 12,
    icono: "fa fa-tasks fa-2x",
    nombre: "Programas Anuales de Adquisiciones",
    url: "ProgramasAnualesDeAdquisiciones",
    target: "_self",
  },
  {
    id: 13,
    icono: "fa fa-address-card-o fa-2x",
    nombre: "Invitaciones",
    url: "Invitaciones",
    target: "_self",
  },
  {
    id: 14,
    icono: "fa fa-tasks fa-2x",
    nombre:
      "Programa Anual de Obra Publica Y Servicions Relacionados con la Misma",
    url: "ProgramaObraPublica",
    target: "_self",
  },
  {
    id: 15,
    icono: "fa fa-file-text fa-2x",
    nombre: "Denuncia Anonima",
    url: "https://servicioscaasim.hidalgo.gob.mx/DenunciaAnonima/",
    target: "_blank",
  },
  {
    id: 16,
    icono: "fa fa-files-o fa-2x",
    nombre: "Protocolo Cero",
    url: "ProtocoloCero",
    target: "_self",
  },
  // {
  //   id: 17,
  //   icono: "fa fa-address-book-o fa-2x",
  //   nombre: "Directorio de Protocolo Cero",
  //   url: "/CAASIM/directorio-protocolo-0/Directorio Protocolo Cero.pdf",
  //   target: "_blank",
  // },
  {
    id: 18,
    icono: "fa fa-tasks fa-2x",
    nombre: "Programa Anual de Disposicion Final de Bienes Muebles",
    url: "ProgramaAnualDFBM",
    target: "_self",
  },
  {
    id: 19,
    icono: "fa fa-tasks fa-2x",
    nombre: "Programa de Mejora Regulatoria",
    url: "MejoraRegulatoria",
    target: "_self",
  },
  {
    id: 20,
    icono: "fa fa-file-text fa-2x",
    nombre: "Manual de Organizacion, Procedimientos y Contabilidad Gubernamental",
    url: "ManualDeOrganizacion",
    target: "_self",
  },
  {
    id: 21,
    icono: "fa fa-file-text fa-2x",
    nombre: "Manual de Normas",
    url: "/CAASIM/manual-de-normas/MODIFICACION AL MANUAL DE NORMAS.pdf",
    target: "_blank",
  },
  {
    id: 22,
    icono: "fa fa-folder fa-2x",
    nombre: "Catalogo de Bienes Muebles e Inmuebles",
    url: "CatalogoBMeI",
    target: "_self",
  },
  {
    id: 23,
    icono: "fa fa-users fa-2x",
    nombre: "Comité de Ética",
    url: "ComiteDeEtica",
    target: "_self",
  },
  {
    id: 24,
    icono: "fa  fa-check-square-o fa-2x",
    nombre: "Control Interno",
    url: "ControlInterno",
    target: "_self",
  },
  {
    id: 25,
    icono: "fa fa-tasks fa-2x",
    nombre: "Programa Anual de Desarrollo Archivistico",
    url: "PADA",
    target: "_self",
  },
  {
    id: 26,
    icono: "fa fa-archive fa-2x",
    nombre: "Instrumentos Archivisticos",
    url: "InstrumentosArchivisticos",
    target: "_self",
  },
  {
    id: 27,
    icono: "fa fa-table fa-2x",
    nombre: "Tabulador",
    url: "Tabulador",
    target: "_self",
  },
  {
    id: 28,
    icono: "fa fa-file-text fa-2x",
    nombre: "Actas de Baja Documental",
    url: "ActasBajaDocumental",
    target: "_self",
  },
  {
    id: 29,
    icono: "fa fa-file-text fa-2x",
    nombre: "Actas de Transferencia Secundaria",
    url: "ActasTransferenciaSecundaria",
    target: "_self",
  },
];
