import React, { useState } from "react";
import { tarifas } from "../../json/tarifas";
import Card from "../Card/Card";
import "./tarifas.css";

function Tarifas() {
  const [selectedYear, setSelectedYear] = useState("");

  const handleChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const filteredTarifas = tarifas.filter(
    (tarifa) => tarifa.year.toString() === selectedYear
  );

  return (
    <div className="container container-mh tarifas-container">
      <div className="secretaria_funciones">
        <h3 className="title">TARIFAS</h3>
        <hr className="hr-gob" />
      </div>
      <div class="tarifas-container">
        <div class="input-group">
          <select
            className="form-select"
            value={selectedYear}
            onChange={handleChange}
          >
            <option value="">Selecciona un año</option>
            {tarifas.map((tarifa, index) => (
              <option key={index} value={tarifa.year}>
                {tarifa.year}
              </option>
            ))}
          </select>
        </div>
        {filteredTarifas.map((tarifa, index) => (
          <div key={index} className="card p-2 mb-5">
            {tarifa.tarifas.map((month, index) => (
              <div key={index}>
                <a
                  className="w-100 d-block p-1 my-link"
                  href={month.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {month.month}
                </a>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="secretaria_funciones pt-5">
        <h3 className="title">CUOTAS Y TARIFAS PERIODICO OFICIAL</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row pb-4">
        <Card
          title="Cuotas y Tarifas del Periódico Oficial"
          href="/CAASIM/cuotas-y-tarifas-periodico-oficial/Cuota y tarifas 2022 POE 31.12.2021.pdf"
          document="Periódico"
          year={2022}
          type={"PDF"}
        />
        <Card
          title="Cuotas y Tarifas del Periódico Oficial"
          href="/CAASIM/cuotas-y-tarifas-periodico-oficial/Cuota y tarifas 2023 POE 31.12.2022.pdf"
          document="Periódico"
          year={2023}
          type={"PDF"}
        />
      </div>
    </div>
  );
}

export default Tarifas;
