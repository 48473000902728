import Card from "../Card/Card";

const ProtocoloCero = () => {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">Protocolo Cero</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
        <Card
          document="Convocatoria Protocolo Cero"
          title="Documento"
          href="/CAASIM/protocolo-cero/Convocatoria Protocolo Cero.pdf"
        />
        <Card
          document="Directorio Consejeros Protocolo Cero"
          title="Documento"
          href="/CAASIM/protocolo-cero/Directorio Consejeros Protocolo Cero.pdf"
        />
        <Card
          document="Pronunciamiento Cero Tolerancia"
          title="Documento"
          href="/CAASIM/protocolo-cero/Pronunciamiento Cero Tolerancia.pdf"
        />
      </div>
    </div>
  );
};

export default ProtocoloCero;
