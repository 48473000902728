import React from "react";
import "./cardInvitaciones.css";
import { obtenerDatosSeleccionados } from "../../methods/2024/methodsInvitaciones2024";

const CardInvitaciones = ({ selectedItemIndex }) => {
  const datosSeleccionados = obtenerDatosSeleccionados(selectedItemIndex);
  // Function to check if the document is new (published within the last 2 days)
  const isNewDocument = (publicationDate) => {
    const TWO_DAYS_IN_MS = 2 * 24 * 60 * 60 * 1000; // Two days in milliseconds
    const currentDate = new Date();
    const diffInMs = currentDate - new Date(publicationDate);
    return diffInMs <= TWO_DAYS_IN_MS;
  };

  return (
    <div id="cardInvitaciones" className="container">
      <div className="row card-content-anio">
        {datosSeleccionados.map((item, index) => (
          <div key={index} className="col-sm-6 col-12">
            <div className="card-body card">
              <h5>{item.nombre}</h5>
              {item.documentos.map((documento, index2) => (
                <div key={index2} className="card_trimestre">
                  <p>
                    {documento.nombre}
                    {documento.contenido.map((contenido) => (
                      <div>
                        {isNewDocument(contenido.publicationDate) && ( // Check if the document is new
                          <span className="badge bg-secondary span-new" style={{ marginRight: '5px' }}>
                            <a
                              href={contenido.new}
                              target="_blank"
                              rel="noreferrer"
                            >
                              NUEVO
                            </a>
                          </span>
                        )}
                        {contenido.excel ? (
                          <span className="badge bg-secondary span-excel">
                            <a
                              href={contenido.excel}
                              target="_blank"
                              rel="noreferrer"
                            >
                              XLSX
                            </a>
                          </span>
                        ) : null}
                        {contenido.pdf ? (
                          <span className="badge bg-secondary span-pdf">
                            <a
                              href={contenido.pdf}
                              target="_blank"
                              rel="noreferrer"
                            >
                              PDF
                            </a>
                          </span>
                        ) : null}
                        {contenido.word ? (
                          <span className="badge bg-secondary span-word">
                            <a
                              href={contenido.word}
                              target="_blank"
                              rel="noreferrer"
                            >
                              DOCX
                            </a>
                          </span>
                        ) : null}
                      </div>
                    ))}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardInvitaciones;
