import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inicio from "./components/Inicio/Inicio";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Transparencia from "./components/Transparencia/Transparencia";
import Rubros from "./components/Rubros/Rubros";
import DetalleRubro from "./components/DetalleRubro/DetalleRubro";
import RubrosHistoricos from "./components/RubrosHistoricos/RubrosHistoricos";
import Agenda from "./components/Agenda/Agenda";
import Servicios from "./components/Servicios/Servicios";
import ArmonizacionContable from "./components/ArmonizacionContable/ArmonizacionContable";
import QuienesSomos from "./components/QuienesSomos/QuienesSomos";
import Modal from "./components/Modal/Modal";
import Licitaciones from "./components/Licitaciones/Licitaciones";
import LinksTransparencia from "./components/LinksTransparencia/LinksTransparencia";
import Tarifas from "./components/Tarifas/Tarifas";
import MunicipiosYSucursales from "./components/MunicipiosYSucursales/MunicipiosYSucursales";
import ContraloriaSocial from "./components/ContraloriaSocial/ContraloriaSocial";
import ProgramaInstitucionalDeDesarrollo from "./components/ProgramaInstitucionalDeDesarrollo/ProgramaInstitucionalDeDesarrollo";
import Invitaciones from "./components/Invitaciones/Invitaciones";
import ProgramasAnualesDeAdquisiciones from "./components/ProgramasAnualesDeAdquisiciones/ProgramasAnualesDeAdquisiciones";
import ProgramaObraPublica from "./components/ProgramaObraPublica/ProgramaObraPublica";
import MejoraRegulatoria from "./components/MejoraRegulatoria/MejoraRegulatoria";
import ManualDeOrganizacion from "./components/ManualDeOrganizacion/ManualDeOrganizacion";
import ProgramaAnualDFBM from "./components/ProgramaAnualDFBM/ProgramaAnualDFBM";
import CatalogoBMeI from "./components/CatalogoBMeI/CatalogoBMeI";
import DetalleLicitaciones from "./components/DetalleLicitaciones/DetalleLicitaciones";
import DetalleInvitaciones from "./components/DetalleInvitaciones/DetalleInvitaciones";
import LicitacionesPublicas2024 from "./components/LicitacionesPublicas/2024/LicitacionesPublicas2024";
import InvitacionesPublicas2024 from "./components/InvitacionesPublicas/2024/InvitacionesPublicas2024";
import InconsistenciasRecibo from "./components/InconsistenciasRecibo/InconsistenciasRecibo";
import Articulos from "./components/Articulos/Articulos";
import ComiteDeEtica from "./components/ComiteDeEtica/ComiteDeEtica";
import ControlInterno from "./components/ControlInterno/ControlInterno";
import Error404 from "./components/Error404/Error404";
import Aviso from "./components/Aviso/Aviso";
import BajaDeUsuario from "./components/BajaDeUsuario/BajaDeUsuario";
import ProtocoloCero from "./components/ProtocoloCero/ProtocoloCero";
import Pada from "./components/Pada/Pada";
import InstrumentosArchivisticos from "./components/InstrumentosArchivisticos/InstrumentosArchivisticos";
import GastoFederalizado from "./components/GastoFederalizado/GastoFederalizado";
import Tabulador from "./components/Tabulador/Tabulador";
import ActasBajaDocumental from "./components/ActasBajaDocumental/ActasBajaDocumental";
import ActasTransferenciaSecundaria from "./components/ActasTransferenciaSecundaria/ActasTransferenciaSecundaria";
import WaterDrop from "./components/WaterDrop/WaterDrop";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route
          exact
          path="/Transparencia/ActasBajaDocumental"
          element={<ActasBajaDocumental />}
        />
        <Route
          exact
          path="/Transparencia/ActasTransferenciaSecundaria"
          element={<ActasTransferenciaSecundaria />}
        />
        <Route exact path="/" element={<Inicio />} />
        <Route exact path="/Transparencia" element={<Transparencia />} />
        <Route exact path="/Aviso" element={<Aviso />} />
        <Route exact path="Aviso/BajaDeUsuario" element={<BajaDeUsuario />} />

        <Route exact path="Transparencia/Rubros" element={<Rubros />} />
        <Route
          exact
          path="Transparencia/Rubros/DetalleRubro/:nombre"
          element={<DetalleRubro />}
        />
        <Route
          exact
          path="Transparencia/Rubros/RubrosHistoricos"
          element={<RubrosHistoricos />}
        />
        <Route exact path="/Agenda" element={<Agenda />} />
        <Route exact path="/Servicios" element={<Servicios />} />
        <Route
          exact
          path="Transparencia/ArmonizacionContable"
          element={<ArmonizacionContable />}
        />
        <Route exact path="/QuienesSomos" element={<QuienesSomos />} />
        <Route exact path="/Modal" element={<Modal />} />
        <Route
          exact
          path="Transparencia/Licitaciones"
          element={<Licitaciones />}
        />
        <Route
          path="Transparencia/LinksTransparencia"
          element={<LinksTransparencia />}
        />
        <Route exact path="Transparencia/Tarifas" element={<Tarifas />} />
        <Route
          exact
          path="Transparencia/MunicipiosYSucursales"
          element={<MunicipiosYSucursales />}
        />
        <Route
          exact
          path="Transparencia/ContraloriaSocial"
          element={<ContraloriaSocial />}
        />
        <Route
          exact
          path="Transparencia/ProgramaInstitucionalDeDesarrollo"
          element={<ProgramaInstitucionalDeDesarrollo />}
        />
        <Route
          exact
          path="Transparencia/ProgramasAnualesDeAdquisiciones"
          element={<ProgramasAnualesDeAdquisiciones />}
        />
        <Route
          exact
          path="Transparencia/Invitaciones"
          element={<Invitaciones />}
        />
        <Route
          exact
          path="Transparencia/ProgramaObraPublica"
          element={<ProgramaObraPublica />}
        />
        <Route
          exact
          path="Transparencia/MejoraRegulatoria"
          element={<MejoraRegulatoria />}
        />
        <Route
          exact
          path="Transparencia/Tabulador"
          element={<Tabulador />}
        />
        <Route
          exact
          path="Transparencia/ManualDeOrganizacion"
          element={<ManualDeOrganizacion />}
        />
        <Route
          exact
          path="Transparencia/ProgramaAnualDFBM"
          element={<ProgramaAnualDFBM />}
        />
        <Route
          exact
          path="Transparencia/CatalogoBMeI"
          element={<CatalogoBMeI />}
        />
        <Route
          exact
          path="Transparencia/Licitaciones/DetalleLicitaciones/:anio"
          element={<DetalleLicitaciones />}
        />
        <Route
          exact
          path="Transparencia/Invitaciones/DetalleInvitaciones/:anio"
          element={<DetalleInvitaciones />}
        />
        <Route
          exact
          path="Transparencia/Licitaciones/2024/LicitacionesPublicas2024"
          element={<LicitacionesPublicas2024 />}
        />
        <Route
          exact
          path="Transparencia/Invitaciones/2024/InvitacionesPublicas2024"
          element={<InvitacionesPublicas2024 />}
        />
        <Route
          exact
          path="Transparencia/InconsistenciasRecibo"
          element={<InconsistenciasRecibo />}
        />
        <Route exact path="Transparencia/Articulos" element={<Articulos />} />
        <Route
          exact
          path="Transparencia/ComiteDeEtica"
          element={<ComiteDeEtica />}
        />
        <Route
          exact
          path="Transparencia/ProtocoloCero"
          element={<ProtocoloCero />}
        />
        <Route
          exact
          path="Transparencia/ControlInterno"
          element={<ControlInterno />}
        />
        <Route exact path="Transparencia/PADA" element={<Pada />} />
        <Route
          exact
          path="Transparencia/InstrumentosArchivisticos"
          element={<InstrumentosArchivisticos />}
        />
        <Route
          exact
          path="Transparencia/GastoFederalizado"
          element={<GastoFederalizado />}
        />

        <Route exact path="*" element={<Error404 />} />
      </Routes>
      <WaterDrop />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
