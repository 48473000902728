import React, { useState } from "react";
import "./inconsistenciasRecibo.css";
import { CATEGORIES } from "../../json/erroresRecibo";

function ErrorComponent() {
  const [selectedError, setSelectedError] = useState(null);

  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title text-uppercase">
          Fallas e Inconsistencias al momento de la toma de lectura de medidor
        </h3>
        <hr className="hr-gob" />
      </div>
      <div className="main-recibos w-100">
        <aside>
          <ul className="ul-recibo">
            {CATEGORIES.map((error, index) => (
              <li key={index} className="category">
                <button
                  onClick={() => setSelectedError(error)}
                  className={
                    "btn-cat btn-category" +
                    (selectedError === error ? " btn-selected-error" : "")
                  }
                  style={{ backgroundColor: error.color }}
                >
                  {error.name}
                </button>
              </li>
            ))}
          </ul>
        </aside>
        <section className="my-d-flex">
          {
            <div className="title-error">
              {selectedError ? selectedError.title : "Selecciona un error"}
            </div>
          }
          <div className="message">
            {
              <h2 className="message-h2">
                {selectedError ? selectedError.name : "Nombre del error"}
              </h2>
            }
            {selectedError ? (
              <p>{selectedError.description}</p>
            ) : (
              <p>Descripcion del error</p>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default ErrorComponent;
