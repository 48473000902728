import { instrumentosArchivisticosSections } from "../../json/instrumentosArchivisticos";

import React from 'react';

const CardSection = ({ data }) => {
  return (
    <div className="col-md-4 col-sm-6 col-12">
      <div className="card_container_historicos">
        <div className="card_anio">
          <h5>{data.title}</h5>
          {data.records.map((record, index) => (
            <div className="card_trimestre" key={index}>
              <p>
                {record.year}
                <span className="badge bg-secondary">
                  <a href={record.url} target="_blank" rel="noreferrer">
                    PDF
                  </a>
                </span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


const InstrumentosArchivisticos = () => {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">INSTRUMENTOS ARCHIVÍSTICOS</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
        {instrumentosArchivisticosSections.map((section, index) => (
          <CardSection key={index} data={section} />
        ))}
      </div>
    </div>
    // <div className="container py-4">
    //   <div className="secretaria_funciones">
    //     <h3 className="title">INSTRUMENTOS ARCHIVÍSTICOS</h3>
    //     <hr className="hr-gob" />
    //   </div>
    //   <div className="row">
    //     {/* SECTION Cuadro General de Clasicacion Archivistica */}
    //     <div className="col-md-4 col-sm-6 col-12">
    //       <div className="card_container_historicos">
    //         <div className="card_anio">
    //           <h5>Cuadro General de Clasificación Archivística</h5>
    //           <div className="card_trimestre">
    //             <p>
    //               2022
    //               <span className="badge bg-secondary">
    //                 <a
    //                   href="/CAASIM/transparencia/2022/Archivo/CUADRO%20GENERAL%20DE%20CLASIFICACION%20ARCHIVISTICA%202022.pdf"
    //                   target="_blank"
    //                   rel="noreferrer"
    //                 >
    //                   PDF
    //                 </a>
    //               </span>
    //             </p>
    //           </div>
    //           <div className="card_trimestre">
    //             <p>
    //               2021
    //               <span className="badge bg-secondary">
    //                 <a
    //                   href="http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CAASIM/dir1/2022/3erTrimestre/AF/SG/Cuadro%20General%20de%20Clasificaci%C3%B3n%202021.pdf"
    //                   target="_blank"
    //                   rel="noreferrer"
    //                 >
    //                   PDF
    //                 </a>
    //               </span>
    //             </p>
    //           </div>
    //           <div className="card_trimestre">
    //             <p>
    //               2020
    //               <span className="badge">
    //                 <a
    //                   href="http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CAASIM/dir5/SG/Archivo/2020/Cuadro%20General%20de%20Clasificaci%C3%B3n%20Archivistica%202020.pdf"
    //                   target="_blank"
    //                   rel="noreferrer"
    //                 >
    //                   PDF
    //                 </a>
    //               </span>
    //             </p>
    //           </div>
    //           <div className="card_trimestre">
    //             <p>
    //               2019
    //               <span className="badge bg-secondary">
    //                 <a
    //                   href="http://transparencia.hidalgo.gob.mx/descargables/ENTIDADES/CAASIM/transparencia2021/1ertrimestre/AyF/Archivo/Escaneo.pdf"
    //                   target="_blank"
    //                   rel="noreferrer"
    //                 >
    //                   PDF
    //                 </a>
    //               </span>
    //             </p>
    //           </div>
    //           <div className="card_trimestre">
    //             <p>
    //               2018
    //               <span className="badge bg-secondary">
    //                 <a
    //                   href="http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CAASIM/dir5/SG/Archivo/2018/Cuadro%20General%20de%20Clasificaci%C3%B3n%20Archiv%C3%ADstica%202018.pdf"
    //                   target="_blank"
    //                   rel="noreferrer"
    //                 >
    //                   PDF
    //                 </a>
    //               </span>
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     {/* SECTION Catalogo de Disposicion Documental */}
    //     <div className="col-md-4 col-sm-6 col-12">
    //       <div className="card_container_historicos">
    //         <div className="card_anio">
    //           <h5>Catálogo de Disposición Documental</h5>
    //           <div className="card_trimestre">
    //             <p>
    //               2020
    //               <span className="badge bg-secondary">
    //                 <a
    //                   href="http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CAASIM/dir1/2023/1er%20Trimestre/AF/SG/CATALOGO%20DE%20DISPOSICI%C3%92N%20DOCUMENTAL%202020.pdf"
    //                   target="_blank"
    //                   rel="noreferrer"
    //                 >
    //                   PDF
    //                 </a>
    //               </span>
    //             </p>
    //           </div>
    //           <div className="card_trimestre">
    //             <p>
    //               2019
    //               <span className="badge bg-secondary">
    //                 <a
    //                   href="http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CAASIM/dir1/2021/4to%20Trimestre/AF/RM/CATALOGO%202019.pdf"
    //                   target="_blank"
    //                   rel="noreferrer"
    //                 >
    //                   PDF
    //                 </a>
    //               </span>
    //             </p>
    //           </div>
    //           <div className="card_trimestre">
    //             <p>
    //               2018
    //               <span className="badge bg-secondary">
    //                 <a
    //                   href="/CAASIM/transparencia/2018/rubros/rubro 45/CATALOGO 2018.pdf"
    //                   target="_blank"
    //                   rel="noreferrer"
    //                 >
    //                   PDF
    //                 </a>
    //               </span>
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     {/* SECTION Guias Documentales */}
    //     <div className="col-md-4 col-sm-6 col-12">
    //       <div className="card_container_historicos">
    //         <div className="card_anio">
    //           <h5>Guías Documentales</h5>
    //           <div className="card_trimestre">
    //             <p>
    //               2019
    //               <span className="badge bg-secondary">
    //                 <a
    //                   href="/CAASIM/transparencia/2019/Archivo/Guias Inventarios 2019.pdf"
    //                   target="_blank"
    //                   rel="noreferrer"
    //                 >
    //                   PDF
    //                 </a>
    //               </span>
    //             </p>
    //           </div>
    //           <div className="card_trimestre">
    //             <p>
    //               2018
    //               <span className="badge bg-secondary">
    //                 <a
    //                   href="https://transparencia.hidalgo.gob.mx/descargables/ENTIDADES/CAASIM/transparencia2020/4toTrimestre/Archivo/GUIA%20DE%20ARCHIVO%20DOCUMENTAL%202018.pdf"
    //                   target="_blank"
    //                   rel="noreferrer"
    //                 >
    //                   PDF
    //                 </a>
    //               </span>
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     {/* SECTION Inventarios Documentales */}
    //     <div className="col-md-4 col-sm-6 col-12">
    //       <div className="card_container_historicos">
    //         <div className="card_anio">
    //           <h5>Inventarios Documentales</h5>
    //           <div className="card_trimestre">
    //             <p>
    //               2019
    //               <span className="badge bg-secondary">
    //                 <a
    //                   href="/CAASIM/transparencia/2019/Archivo/Inventario Documental 2019.pdf"
    //                   target="_blank"
    //                   rel="noreferrer"
    //                 >
    //                   PDF
    //                 </a>
    //               </span>
    //             </p>
    //           </div>
    //           <div className="card_trimestre">
    //             <p>
    //               2018
    //               <span className="badge bg-secondary">
    //                 <a
    //                   href="https://transparencia.hidalgo.gob.mx/descargables/ENTIDADES/CAASIM/transparencia2021/1ertrimestre/AyF/Archivo/Inventario%20Documental%202018.pdf"
    //                   target="_blank"
    //                   rel="noreferrer"
    //                 >
    //                   PDF
    //                 </a>
    //               </span>
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default InstrumentosArchivisticos