import React from "react";
import "./manualDeOrganizacion.css";
import Card from "../Card/Card";

function ManualDeOrganizacion() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title text-uppercase">
          Manual de Organización, Procedimientos y Contabilidad Gubernamental de
          la CAASIM
        </h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
        <Card
          document="Manual"
          title="de Organización"
          href="/CAASIM/manual-organizacion/MANUAL DE ORGANIZACIÓN-1.pdf"
        />
        <Card
          document="Manual"
          title="de Procedimientos"
          href="/CAASIM/manual-organizacion/MANUAL DE PROCEDIMIENTOS-1.pdf"
        />
        <Card
          document="Manual"
          title="de Contabilidad Gubernametal"
          href="/CAASIM/manual-organizacion/MCG 30-09-2023.pdf"
        />
      </div>
    </div>
  );
}

export default ManualDeOrganizacion;
