import React from "react";
import "./servicios.css";
import { servicios } from "../../json/servicios";
import { Link } from "react-router-dom";

const Servicios = () => {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">SERVICIOS</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
      {servicios.map((item, index) => (
          <Link
            key={index}
            to={item.url}
            className="col-md-4 col-sm-6 col-12 servicio_enlace"
            target="_blank">
            <div className="servicio_container">
              <div className="servicio_icono">
                <i className={item.icono}></i>
              </div>
              <h3>{item.nombre}</h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Servicios;
