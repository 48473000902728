export const instrumentosArchivisticosSections = [
  {
    "title": "Cuadro General de Clasificación Archivística",
    "records": [
      {
        "year": "2022",
        "url": "/CAASIM/transparencia/2022/Archivo/CUADRO%20GENERAL%20DE%20CLASIFICACION%20ARCHIVISTICA%202022.pdf"
      },
      {
        "year": "2021",
        "url": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CAASIM/dir1/2022/3erTrimestre/AF/SG/Cuadro%20General%20de%20Clasificaci%C3%B3n%202021.pdf"
      },
      {
        "year": "2020",
        "url": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CAASIM/dir5/SG/Archivo/2020/Cuadro%20General%20de%20Clasificaci%C3%B3n%20Archivistica%202020.pdf"
      },
      {
        "year": "2019",
        "url": "http://transparencia.hidalgo.gob.mx/descargables/ENTIDADES/CAASIM/transparencia2021/1ertrimestre/AyF/Archivo/Escaneo.pdf"
      },
      {
        "year": "2018",
        "url": "http://transparencia.hidalgo.gob.mx/descargables/ENTIDADES/CAASIM/transparencia2021/1ertrimestre/AyF/Archivo/Escaneo.pdf"
      },
      {
        "year": "2017",
        "url": '/CAASIM/instrumentos-archivisticos/2017/Cuadro General de Clasificación Archivistica 2017.pdf'
      },
      {
        "year": "2016",
        "url": '/CAASIM/instrumentos-archivisticos/2016/Cuadro General de Clasificación Archivistica 2016.pdf'
      },
      {
        "year": "2015",
        "url": '/CAASIM/instrumentos-archivisticos/2015/Cuadro General de Clasificación Archivistica 2015.pdf'
      },
      {
        "year": "2014",
        "url": '/CAASIM/instrumentos-archivisticos/2014/Cuadro general de Clasificación Archivistica 2014.pdf'
      },
      {
        "year": "2013",
        "url": '/CAASIM/instrumentos-archivisticos/2013/Cuadro de Clasificación Archivistica 2013.pdf'
      },
      {
        "year": "2012",
        "url": '/CAASIM/instrumentos-archivisticos/2012/Cuadro General de Clasificación Archivistica 2012.pdf'
      },
      {
        "year": "2011",
        "url": '/CAASIM/instrumentos-archivisticos/2011/Cuadro General de Clasificación Archivistica 2011.pdf'
      },
      {
        "year": "2010",
        "url": '/CAASIM/instrumentos-archivisticos/2010/Cuadro General de Clasificación Archivistica 2010.pdf'
      },
      {
        "year": "2009",
        "url": '/CAASIM/instrumentos-archivisticos/2009/Cuadro General de Clasificación Archivistica  2009.pdf'
      },
      {
        "year": "2008",
        "url": '/CAASIM/instrumentos-archivisticos/2008/Cuadro General de Clasificación Archivistica 2008.pdf'
      },
    ]
  },
  {
    "title": "Catálogo de Disposición Documental",
    "records": [
      {
        "year": "2020",
        "url": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CAASIM/dir1/2023/1er%20Trimestre/AF/SG/CATALOGO%20DE%20DISPOSICI%C3%92N%20DOCUMENTAL%202020.pdf"
      },
      {
        "year": "2019",
        "url": "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CAASIM/dir1/2021/4to%20Trimestre/AF/RM/CATALOGO%202019.pdf"
      },
      {
        "year": "2018",
        "url": "/CAASIM/transparencia/2018/rubros/rubro 45/CATALOGO 2018.pdf"
      },
      {
        'year': '2017',
        'url': '/CAASIM/instrumentos-archivisticos/2017/Catalogo de Disposición Documental 2017.pdf'
      },
      {
        'year': '2016',
        'url': '/CAASIM/instrumentos-archivisticos/2016/Catalogo de Disposición Documental 2016.pdf'
      },
      {
        'year': '2015',
        'url': '/CAASIM/instrumentos-archivisticos/2015/Catalogo de disposición Documental 2015.pdf'
      },
      {
        'year': '2014',
        'url': '/CAASIM/instrumentos-archivisticos/2014/Catálogo de Disposición Documental 2014.pdf'
      },
      {
        'year': '2013',
        'url': '/CAASIM/instrumentos-archivisticos/2013/Catálogo de Disposición Documental 2013.pdf'
      },
      {
        'year': '2012',
        'url': '/CAASIM/instrumentos-archivisticos/2012/Catalogo de Disposición Documental 2012.pdf'
      },
      {
        'year': '2011',
        'url': '/CAASIM/instrumentos-archivisticos/2011/Catálogo de Disposición Documental 2011.pdf'
      },
      {
        'year': '2010',
        'url': '/CAASIM/instrumentos-archivisticos/2010/Catálogo de Disposición Documental 2010.pdf'
      },
      {
        'year': '2009',
        'url': '/CAASIM/instrumentos-archivisticos/2009/Catálogo de Disposición Documental 2009.pdf'
      },
      {
        'year': '2008',
        'url': '/CAASIM/instrumentos-archivisticos/2008/Catalogo de Disposición Documental 2008.pdf'
      }
    ]
  },
  {
    "title": "Guías Documentales",
    "records": [
      {
        "year": "2019",
        "url": "/CAASIM/transparencia/2019/Archivo/Guias Inventarios 2019.pdf"
      },
      {
        "year": "2018",
        "url": "https://transparencia.hidalgo.gob.mx/descargables/ENTIDADES/CAASIM/transparencia2020/4toTrimestre/Archivo/GUIA%20DE%20ARCHIVO%20DOCUMENTAL%202018.pdf"
      },
      {
        "year": "2017",
        "url": "/CAASIM/instrumentos-archivisticos/2017/Guia de Archivo Documental 2017.pdf"
      },
      {
        "year": "2016",
        "url": "/CAASIM/instrumentos-archivisticos/2016/Guia de Archivo Documental 2016.pdf"
      },
      {
        "year": "2015",
        "url": "/CAASIM/instrumentos-archivisticos/2015/Guia de Archivo Documental 2015.pdf"
      },
      {
        "year": "2014",
        "url": "/CAASIM/instrumentos-archivisticos/2014/Guia de Archivo Documental 2014.pdf"
      },
      {
        "year": "2013",
        "url": "/CAASIM/instrumentos-archivisticos/2013/Guia de Archivo Documental 2013.pdf"
      },
      {
        "year": "2012",
        "url": "/CAASIM/instrumentos-archivisticos/2012/Guia de Archivo Documental 2012.pdf"
      },
      {
        "year": "2011",
        "url": "/CAASIM/instrumentos-archivisticos/2011/Guia de Archivo Documental 2011.pdf"
      },
      {
        "year": "2010",
        "url": "/CAASIM/instrumentos-archivisticos/2010/Guia de Archivo Documental 2010.pdf"
      },
      {
        "year": "2009",
        "url": "/CAASIM/instrumentos-archivisticos/2009/Guia de Archivo Documental 2009.pdf"
      },
      {
        "year": "2008",
        "url": "/CAASIM/instrumentos-archivisticos/2008/Guia de Archivo Documental 2008.pdf"
      }
    ]
  },
  {
    "title": "Inventarios Documentales",
    "records": [
      {
        "year": "2019",
        "url": "/CAASIM/transparencia/2019/Archivo/Inventario Documental 2019.pdf"
      },
      {
        "year": "2018",
        "url": "https://transparencia.hidalgo.gob.mx/descargables/ENTIDADES/CAASIM/transparencia2021/1ertrimestre/AyF/Archivo/Inventario%20Documental%202018.pdf"
      },
      {
        "year": "2017",
        "url": "/CAASIM/instrumentos-archivisticos/2017/Inventario Documental 2017.pdf"
      },
      {
        "year": "2016",
        "url": "/CAASIM/instrumentos-archivisticos/2016/Inventario Documental 2016.pdf"
      },
      {
        "year": "2015",
        "url": "/CAASIM/instrumentos-archivisticos/2015/Inventario Documental 2015.pdf"
      },
      {
        "year": "2014",
        "url": "/CAASIM/instrumentos-archivisticos/2014/Inventario Documental 2014.pdf"
      },
      {
        "year": "2013",
        "url": "/CAASIM/instrumentos-archivisticos/2013/Inventario Documental 2013.pdf"
      },
      {
        "year": "2012",
        "url": "/CAASIM/instrumentos-archivisticos/2012/Inventario Documental 2012.pdf"
      },
      {
        "year": "2011",
        "url": "/CAASIM/instrumentos-archivisticos/2011/Inventario Documental 2011.pdf"
      },
      {
        "year": "2010",
        "url": "/CAASIM/instrumentos-archivisticos/2010/Inventario Documental 2010.pdf"
      },
      {
        "year": "2009",
        "url": "/CAASIM/instrumentos-archivisticos/2009/Inventario Documental 2009 .pdf"
      },
      {
        "year": "2008",
        "url": "/CAASIM/instrumentos-archivisticos/2008/Inventario Documental 2008.pdf"
      }
    ]
  },
]  