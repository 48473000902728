import React from "react";
import "./detalleLicitaciones.css";
import { licitaciones } from "../../json/licitacionesItems";
import { useParams } from "react-router-dom";

const DetalleLicitaciones = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { anio } = useParams();
  const licitacion = licitaciones.find(
    (licitacion) => licitacion.anio === anio
  );

  const MyTable = ({ formatos }) => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Archivo</th>
            <th>Tipo</th>
          </tr>
        </thead>
        <tbody>
          {formatos.map((formato, i) => (
            <tr key={i}>
              <td>{formato[0]}</td>
              <td>
                <a
                  href={formato[1].link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className=""
                >
                  {formato[1].text}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const [searchTerm, setSearchTerm] = React.useState('');
  const [filteredFormatos, setFilteredFormatos] = React.useState(licitacion.formatos);

  React.useEffect(() => {
    if (searchTerm) {
      setFilteredFormatos(licitacion.formatos.filter(formato => 
        formato[0].toLowerCase().includes(searchTerm.toLowerCase())
      ));
    } else {
      setFilteredFormatos(licitacion.formatos);
    }
  }, [searchTerm, licitacion.formatos]);

  return (
    <div className="container container_detalle_rubro">
      <div className="row">
        <div className="col-12">
          <div className="rubro_titulo">
            <h3 className="title">LICITACIONES {licitacion.anio}</h3>
            <hr className="hr-gob mb-3" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-2">
          <input 
            className="search-licitaciones"
            type="text" 
            placeholder="Busca un archivo..." 
            value={searchTerm} 
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <MyTable formatos={filteredFormatos} />
      </div>
    </div>
  );
};

export default DetalleLicitaciones;
