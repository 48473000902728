export const inv01 = [
  {
    nombre: "Bases CAASIM-HGO-INV3-OP-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-01-2024/BASES CAASIM-HGO-INV3-OP-01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Catalogo Mudo CAASIM-HGO-INV3-OP-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-01-2024/CATALOGO MUDO CAASIM-HGO-INV3-OP-01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Plano de Localización CAASIM-HGO-INV3-OP-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-01-2024/PLANO DE LOCALIZACIÓN CAASIM-HGO-INV3-OP-01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Junta de Aclaraciones CAASIM-HGO-INV3-OP-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-01-2024/JUNTA DE ACLARACIONES CAASIM-HGO-INV3-OP-01-2024001.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
  {
    nombre: "Presentación y Apertura CAASIM-HGO-INV3-OP-01-2024",
    documentos: [
      {
        nombre: "Archivo",
        contenido: [
          {
            // excel: "www.google.com",
            pdf: "/CAASIM/invitaciones/2024/CAASIM-HGO-INV3-OP-01-2024/PRESENTACIÓN Y APERTURA DE PROPOSICIONES CAASIM-HGO-INV3-OP-01-2024.pdf",
            // word: "www.google.com",
          },
        ],
      },
    ],
  },
];
