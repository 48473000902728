import React, { useEffect } from "react";
// import { useState } from "react";
import "./licitaciones.css";
import { licitaciones } from "../../json/licitacionesItems";
import { Link } from "react-router-dom";

function Licitaciones() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">LICITACIONES</h3>
        <hr className="hr-gob" />
      </div>
      <div className="m-auto">
        {/* <MyTable /> */}
        <div className="row">
          <Link
            to={"2024/LicitacionesPublicas2024"}
            className="col-md-4 col-sm-6 col-12 rubro_enlace"
          >
            <div className="rubro_container">
              <div className="rubro_icono text-white">
                <i className="fa fa-file-text-o fa-2x"></i>
              </div>
              <h3>2024</h3>
            </div>
          </Link>
          {licitaciones.map((item, index) => (
            <Link
              key={index}
              to={"DetalleLicitaciones/" + item.anio}
              className="col-md-4 col-sm-6 col-12 rubro_enlace"
            >
              <div className="rubro_container">
                <div className="rubro_icono text-white">
                  <i className={item.icono}></i>
                </div>
                <h3>{item.anio}</h3>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Licitaciones;
