export const rubrosHistoricos = [
  {
    anio: "2023",
    descripcion: "Disponibilidad de los trimestres anteriores",
    trimestres: [
      {
        trimestre: "Primer Trimestre",
        url: "/CAASIM/48-rubros/2023/1erTrimestre2023CAASIM.zip",
      },
      {
        trimestre: "Segundo Trimestre",
        url: "/CAASIM/48-rubros/2023/2doTrimestre2023CAASIM.zip",
      },
      {
        trimestre: "Tercer Trimestre",
        url: "/CAASIM/48-rubros/2023/3erTrimestre2023CAASIM.zip",
      },
      {
        trimestre: "Cuarto Trimestre",
        url: "/CAASIM/48-rubros/2023/4toTrimestre2023CAASIM.zip",
      },
    ],
  },
  {
    anio: "2022",
    descripcion: "Disponibilidad de los trimestres anteriores",
    trimestres: [
      {
        trimestre: "Primer Trimestre",
        url: "/CAASIM/48-rubros/2022/1erTrimestre2022CAASIM.zip",
      },
      {
        trimestre: "Segundo Trimestre",
        url: "/CAASIM/48-rubros/2022/2doTrimestre2022CAASIM.zip",
      },
      {
        trimestre: "Tercer Trimestre",
        url: "/CAASIM/48-rubros/2022/3erTrimestre2022CAASIM.zip",
      },
      {
        trimestre: "Cuarto Trimestre",
        url: "/CAASIM/48-rubros/2022/4toTrimestre2022CAASIM.zip",
      },
    ],
  },
  {
    anio: "2021",
    descripcion: "Disponibilidad de los trimestres anteriores",
    trimestres: [
      {
        trimestre: "Primer Trimestre",
        url: "/CAASIM/48-rubros/2021/1erTrimestre2021CAASIM.zip",
      },
      {
        trimestre: "Segundo Trimestre",
        url: "/CAASIM/48-rubros/2021/2doTrimestre2021CAASIM.zip",
      },
      {
        trimestre: "Tercer Trimestre",
        url: "/CAASIM/48-rubros/2021/3erTrimestre2021CAASIM.zip",
      },
      {
        trimestre: "Cuarto Trimestre",
        url: "/CAASIM/48-rubros/2021/4toTrimestre2021CAASIM.zip",
      },
    ],
  },
  {
    anio: "2020",
    descripcion: "Disponibilidad de los trimestres anteriores",
    trimestres: [
      {
        trimestre: "Primer Trimestre",
        url: "/CAASIM/48-rubros/2020/1erTrimestre2020CAASIM.zip",
      },
      {
        trimestre: "Segundo Trimestre",
        url: "/CAASIM/48-rubros/2020/2doTrimestre2020CAASIM.zip",
      },
      {
        trimestre: "Tercer Trimestre",
        url: "/CAASIM/48-rubros/2020/3erTrimestre2020CAASIM.zip",
      },
      {
        trimestre: "Cuarto Trimestre",
        url: "/CAASIM/48-rubros/2020/4toTrimestre2020CAASIM.zip",
      },
    ],
  },
  {
    anio: "2019",
    descripcion: "Disponibilidad de los trimestres anteriores",
    trimestres: [
      {
        trimestre: "Primer Trimestre",
        url: "/CAASIM/48-rubros/2019/1erTrimestre2019CAASIM.zip",
      },
      {
        trimestre: "Segundo Trimestre",
        url: "/CAASIM/48-rubros/2019/2doTrimestre2019CAASIM.zip",
      },
      {
        trimestre: "Tercer Trimestre",
        url: "/CAASIM/48-rubros/2019/3erTrimestre2019CAASIM.zip",
      },
      {
        trimestre: "Cuarto Trimestre",
        url: "/CAASIM/48-rubros/2019/4toTrimestre2019CAASIM.zip",
      },
    ],
  },
  // {
  //   anio: "2018",
  //   descripcion: "Disponibilidad de los trimestres anteriores",
  //   trimestres: [
  //     {
  //       trimestre: "Primer Trimestre",
  //       url: "",
  //     },
  //     {
  //       trimestre: "Segundo Trimestre",
  //       url: "",
  //     },
  //     {
  //       trimestre: "Tercer Trimestre",
  //       url: "",
  //     },
  //     {
  //       trimestre: "Cuarto Trimestre",
  //       url: "",
  //     },
  //   ],
  // },
  // {
  //   anio: "2015 - 2017",
  //   descripcion: "Ejercicios Anteriores",
  //   trimestres: [
  //     {
  //       trimestre: "Plataforma Nacional",
  //       url: "",
  //     },
  //   ],
  // },
  // {
  //   anio: "Información Histórica 21 Rubros",
  //   descripcion: "Ejercicios Anteriores",
  //   trimestres: [
  //     {
  //       trimestre: "Descargar",
  //       url: "",
  //     },
  //   ],
  // },
];
