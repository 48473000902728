export const licitaciones2024 = [
  {
    titulo: "Convocatoria 01-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N1-2024",
      },
      {
        informacionTitulo: "EA-913005999-N2-2024",
      },
      {
        informacionTitulo: "EA-913005999-N3-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 02-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N4-2024",
      },
      {
        informacionTitulo: "EA-913005999-N5-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 03-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N6-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 01-2024 OBRA",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-01-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 04-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N7-2024",
      },
    ],
  },

  {
    titulo: "Convocatoria 05-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N8-2024",
      },
      {
        informacionTitulo: "EA-913005999-N9-2024",
      },
      {
        informacionTitulo: "EA-913005999-N10-2024",
      },
      {
        informacionTitulo: "EA-913005999-N11-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 06-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N12-2024",
      },
      {
        informacionTitulo: "EA-913005999-N13-2024",
      },
      {
        informacionTitulo: "EA-913005999-N14-2024",
      },
      {
        informacionTitulo: "EA-913005999-N15-2024",
      },
      {
        informacionTitulo: "EA-913005999-N16-2024",
      },
      {
        informacionTitulo: "EA-913005999-N17-2024",
      },
      {
        informacionTitulo: "EA-913005999-N18-2024",
      },
      {
        informacionTitulo: "EA-913005999-N19-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 07-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N20-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria Multiple 02-2024",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-02-2024",
      },
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-03-2024",
      },
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-04-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 03-2024 OBRA",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-05-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 08-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N22-2024",
      },
      {
        informacionTitulo: "EA-913005999-N24-2024",
      },
      {
        informacionTitulo: "EA-913005999-N25-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 09-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N21-2024",
      },
      {
        informacionTitulo: "EA-913005999-N26-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 10-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N27-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 04-2024 OBRA",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-06-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 11-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N28-2024",
      },
      {
        informacionTitulo: "EA-913005999-N29-2024",
      },
      {
        informacionTitulo: "EA-913005999-N30-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria Multiple 05-2024",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-07-2024",
      },
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-08-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 12-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N31-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 06-2024 OBRA",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-09-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 13-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N32-2024",
      },
      {
        informacionTitulo: "EA-913005999-N33-2024",
      },
    ],
  }, 
];
