import "./programaInstitucionalDeDesarrollo.css";
import Card from "../Card/Card";

function ProgramaInstitucionalDeDesarrollo() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">PROGRAMA INSTITUCIONAL DE DESARROLLO</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
        <Card
          document="Información"
          description="Programa Institucional de Desarrollo"
          title="P.I.D. 2022"
          href="/CAASIM/PID/2022/PID 2020-2022 CAASIM.pdf"
          type={"PDF"}
        />
      </div>
    </div>
  );
}

export default ProgramaInstitucionalDeDesarrollo;
