import Card from "../Card/Card";

const Pada = () => {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">PROGRAMA ANUAL DE DESARROLLO ARCHIVISTICO</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
        <Card
          document="Programa Anual de Desarrollo Archivístico 2024"
          href="/CAASIM/PADA/2024/PADA 2024.pdf"
          title="Archivo"
          year={2024}
          type={"PDF"}
        />
        <Card
          document="Informe del Programa Anual de Desarrollo Archivístico 2023"
          href="/CAASIM/PADA/2023/Informe PADA 2023.pdf"
          title="Archivo"
          year={2023}
          type={"PDF"}
        />
        <Card
          document="Programa Anual de Desarrollo Archivístico 2023"
          href="/CAASIM/PADA/2023/PADA 2023.pdf"
          title="Archivo"
          year={2023}
          type={"PDF"}
        />
        <Card
          document="Informe del Programa Anual de Desarrollo Archivístico 2022"
          href="/CAASIM/PADA/2022/Informe PADA 2022.pdf"
          title="Archivo"
          year={2022}
          type={"PDF"}
        />
      </div>
    </div>
  );
};

export default Pada;
