import React from "react";
import "./programaObraPublica.css";
import Card from "../Card/Card";

function ProgramaObraPublica() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">
          PROGRAMA ANUAL DE OBRA PÚBLICA Y SERVICIOS RELACIONADOS CON LA MISMA
        </h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
        <Card document="Programa Anual de Obra Pública" year={2023} type={"PDF"} />
      </div>
    </div>
  );
}

export default ProgramaObraPublica;
