import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./transparencia.css";
import { transparencia_item } from "../../json/transparenciaItem";

const Transparencia = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Sorting the array alphabetically by the 'nombre' property, considering numbers
  transparencia_item.sort((a, b) => {
    const nameA = a.nombre.toLowerCase();
    const nameB = b.nombre.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return (
    <div className="container transparencia-container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">TRANSPARENCIA</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row pb-5">
        {transparencia_item.map((item, index) => (
          <Link
            key={index}
            to={item.url}
            target={item.target}
            className="col-md-4 col-sm-6 col-12 servicio_enlace"
          >
            <div className="servicio_container">
              <div className="servicio_icono">
                <i className={item.icono}></i>
              </div>
              <h3>{item.nombre}</h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Transparencia;
