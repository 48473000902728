import React from "react";
import Card from "../Card/Card";

const data = [
  {
    year: "2009-2016",
    url: "/CAASIM/actas-de-transferencia-secundaria/Acta Transferencia Secundaria Direccion Comercial  (2009-2016).pdf",
  },
];

function ActasTransferenciaSecundaria() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">ACTAS DE TRANSFERENCIA SECUNDARIA</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
        {data.map((programa, index) => (
          <Card
            key={index}
            document="Acta de Transferencia Secundaria Dirección Comercial"
            href={programa.url}
            year={programa.year}
            type={"PDF"}
          />
        ))}
      </div>
    </div>
  );
}

export default ActasTransferenciaSecundaria;
