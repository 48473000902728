import React, { useState } from 'react';
import './linksTransparencia.css';
import { linksTransparencia } from '../../json/linksTransparencia';

function LinksTransparencia() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">Links Transparencia</h3>
        <hr className="hr-gob" />
      </div>
      <div className="m-auto">
        <MyTable />
      </div>
    </div>
  );
}

function MyTable() {
  const [data] = useState([...linksTransparencia]);

  return (
    <table className="table">
      <thead>
        <tr>
          {data[0].map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.slice(1).map((row, i) => (
          <tr key={i}>
            {row.map((cell, j) => (
              <td key={j}>
                {typeof cell === "object" &&
                (cell.text === "PDF" || cell.text === "DOCX") ? (
                  <a
                    href={cell.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-flex justify-content-center"
                  >
                    {cell.text}
                  </a>
                ) : (
                  cell
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default LinksTransparencia;