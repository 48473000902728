
export const CATEGORIES = [
  {
    name: "A 02",
    title: "MEDIDOR PARA SUSTITUCIÓN",
    description: <ComponentA02 />,
  },
  {
    name: "C",
    title: "DOMICILIO NO LOCALIZADO",
    description: <ComponentC />,
  },
  {
    name: "C 01",
    title: "TOMA NO LOCALIZADA",
    description: <ComponentC01 />,
  },
  {
    name: "F",
    title: "MEDIDOR AL REVES",
    description: <ComponentF />,
  },
  {
    name: "F 01",
    title: "MEDIDOR MANIPULADO",
    description: <ComponentF01 />,
  },
  {
    name: "G",
    title: "CASA CERRADA",
    description: <ComponentG />,
  },
  {
    name: "G 1",
    title: "EDIFICIO CERRADO",
    description: <ComponentG1 />,
  },
  {
    name: "G 2",
    title: "PRIVADA CERRADA",
    description: <ComponentG2 />,
  },
  {
    name: "H",
    title: "TOMA SIN MEDIDOR",
    description: <ComponentH />,
  },
  {
    name: "I 01",
    title: "OBSTÁCULOS (PERRO)",
    description: <ComponentI01 />,
  },
  {
    name: "I 02",
    title: "OBSTRUCCIÓN FÍSICA",
    description: <ComponentI02 />,
  },
  {
    name: "I 03",
    title: "CORRESPONDE A OTRA RUTA",
    description: <ComponentI03 />,
  },
  {
    name: "K 01",
    title: "TOMA CORTADA SIN MEDIDOR",
    description: <ComponentK />,
  },
];

function ComponentA02() {
  return (
    <>
      Derivado de las medidas de mejora continua en atención a personas usuarias
      y en los servicios que ofrece{" "}
      <a
        href="https://caasim.hidalgo.gob.mx"
        target="_blank"
        rel="noreferrer"
        className="caasim-link"
      >
        La Comisión de Agua y Alcantarillado de Sistemas Intermunicipales,
      </a>{" "}
      se le informa que el medidor de agua potable que actualmente tiene
      instalado, <strong>no registra consumos</strong>. En razón de lo anterior y con fundamento
      en los{" "}
      <a
        href="Articulos"
        className="articulos-link"
        target="_blank"
        rel="noreferrer"
      >
        artículos 115 y 154 de la Ley Estatal de Agua y Alcantarillado para el
        Estado de Hidalgo y 42 de su Reglamento;
      </a>{" "}
      <strong>
        Se informa que se llevara a cabo la instalación de un medidor nuevo el
        cual no tendrá costo. Por lo que es necesario permita que el personal de
        este Organismo realice los trabajos necesarios
      </strong> de acuerdo a la facultad
      potestativa de esta organismo.
    </>
  );
}

function ComponentC() {
  return (
    <>
      Derivado de las medidas de mejora continua en atención a personas usuarias
      y en los servicios que ofrece{" "}
      <a
        href="https://caasim.hidalgo.gob.mx"
        target="_blank"
        rel="noreferrer"
        className="caasim-link"
      >
        La Comisión de Agua y Alcantarillado de Sistemas Intermunicipales,
      </a>{" "}
      se le informa que <strong>su domicilio no ha sido localizado</strong>, lo que impide
      registrar su consumo de agua real, por lo que se le hace una cordial
      invitación para acudir a la{" "}
      <a
        href="https://clausa.app.carto.com/map/377e629f-a9b2-44ea-a1db-50940252ec36"
        target="_blank"
        rel="noreferrer"
        className="sucursal-link"
      >
        Sucursal CAASIM
      </a>{" "}
      mas cercana a su domicilio en el área de Atención a Usuarios, con la
      finalidad de <strong>
        realizar las acciones correspondientes a la actualización de
        datos respecto a la ubicación geográfica de su domicilio.
      </strong>
    </>
  );
}

function ComponentC01() {
  return (
    <>
      Derivado de las medidas de mejora continua en atención a personas usuarias
      y en los servicios que ofrece{" "}
      <a
        href="https://caasim.hidalgo.gob.mx"
        target="_blank"
        rel="noreferrer"
        className="caasim-link"
      >
        La Comisión de Agua y Alcantarillado de Sistemas Intermunicipales,
      </a>{" "}
      se le informa que en su domicilio <strong>
        no se encontraron indicios de su toma de
        agua potable,
      </strong> lo que impide registrar su consumo real, por lo que se le
      hace una cordial invitación para acudir a la{" "}
      <a
        href="https://clausa.app.carto.com/map/377e629f-a9b2-44ea-a1db-50940252ec36"
        target="_blank"
        rel="noreferrer"
        className="sucursal-link"
      >
        Sucursal CAASIM
      </a>{" "}
      mas cercana a su domicilio en el área de Atención a Usuarios de este
      Organismo <strong>para proporcionar mayores datos de ubicación.</strong>
    </>
  );
}

function ComponentF() {
  return (
    <>
      Derivado de las medidas de mejora continua en atención a personas usuarias
      y en los servicios que ofrece{" "}
      <a
        href="https://caasim.hidalgo.gob.mx"
        target="_blank"
        rel="noreferrer"
        className="caasim-link"
      >
        La Comisión de Agua y Alcantarillado de Sistemas Intermunicipales,
      </a>{" "}
      se le informa que su medidor <strong>
        se encuentra instalado en sentido contrario a
        la medición,
      </strong> por lo que debe instalarse correctamente (en dirección
      opuesta). Es necesario que realice el trámite correspondiente por lo que
      se le hace una cordial invitación para acudir a la{" "}
      <a
        href="https://clausa.app.carto.com/map/377e629f-a9b2-44ea-a1db-50940252ec36"
        target="_blank"
        rel="noreferrer"
        className="sucursal-link"
      >
        Sucursal CAASIM
      </a>{" "}
      mas cercana a su domicilio en el área de Atención a Usuarios. <strong>
        Una vez
        corregida la posición del medidor,
      </strong> se podrá registrar su consumo real de
      manera mensual y evitar, así, una facturación de consumo presuntivo que
      pueda afectar su economía.
    </>
  );
}

function ComponentF01() {
  return (
    <>
      Derivado de las medidas de mejora continua en atención a personas usuarias
      y en los servicios que ofrece{" "}
      <a
        href="https://caasim.hidalgo.gob.mx"
        target="_blank"
        rel="noreferrer"
        className="caasim-link"
      >
        La Comisión de Agua y Alcantarillado de Sistemas Intermunicipales
      </a>
      , se le informa que su medidor se encontró con <strong>
        indicios de manipulación,
        por lo que se debera realizar una prueba de funcionamiento.
      </strong> Es necesario
      que realice el trámite correspondiente por lo que se le hace una cordial
      invitación para acudir a la{" "}
      <a
        href="https://clausa.app.carto.com/map/377e629f-a9b2-44ea-a1db-50940252ec36"
        target="_blank"
        rel="noreferrer"
        className="sucursal-link"
      >
        Sucursal CAASIM
      </a>{" "}
      mas cercana a su domicilio en el área de Atención a Usuarios de este
      Organismo o en su caso solicitar el trámite en línea. <strong>
        Una vez realizada la
        prueba de funcionamiento, se registrará su consumo real de manera mensual
      </strong>
      para evitar una facturación de consumo presuntivo que pueda afectar su
      economía.
    </>
  );
}

function ComponentG() {
  return (
    <>
      Debido a que su medidor se encuentra <strong>
        instalado en el interior de su
        domicilio,
      </strong> resulta imposible registrar su consumo real, por lo que se hace
      de su conocimiento que, de conformidad con el <a href="Articulos"
        className="articulos-link"
        target="_blank"
        rel="noreferrer">
        artículos 115 de la Ley
        Estatal de Agua y Alcantarillado para el Estado de Hidalgo y 42 de su
        Reglamento;
      </a> <strong>Usted debe reubicar el medidor en el exterior del domicilio,</strong>
      para poder registrar su consumo real de manera mensual y evitar una
      facturación de consumo presuntivo que pueda afectar su economía. <strong>
        Una vez
        realizado lo anterior, deberá notificarlo a este Organismo a través de sus 
        {" "}<a
          href="https://clausa.app.carto.com/map/377e629f-a9b2-44ea-a1db-50940252ec36"
          target="_blank"
          rel="noreferrer"
          className="sucursal-link"
        >
           Sucursales CAASIM
        </a>{" "}
        para que se realice una supervisión y corroborar la reubicación y la
        correcta instalación del medidor y evitar así alguna infracción.
      </strong>
    </>
  );
}

function ComponentG1() {
  return (
    <>
      Inconsistencia generada debido a que <strong>
        no hay acceso al edifico donde se
        encuentra instalado el medidor,
      </strong> resulta imposible registrar el consumo
      real, por lo que se hace de su conocimiento lo establecido y de
      conformidad con el <a href="Articulos"
        className="articulos-link"
        target="_blank"
        rel="noreferrer">
        artículos 115 de la Ley Estatal de Agua y
        Alcantarillado para el Estado de Hidalgo y 42 de su Reglamento;
      </a> donde se
      menciona que se deberá garantizar el acceso del personal de este
      Organismo, para poder registrar su consumo real de manera mensual y evitar
      una facturación de consumo presuntivo que pueda afectar su economía.
    </>
  );
}

function ComponentG2() {
  return (
    <>
      Debido a que <strong>
        no hay acceso a la privada donde se encuentra instalado el
        medidor,
      </strong> se impide registrar su consumo real, por lo que se hace de su
      conocimiento lo establecido y de conformidad con el <a href="Articulos"
        className="articulos-link"
        target="_blank"
        rel="noreferrer">
        artículos 115 de la
        Ley Estatal de Agua y Alcantarillado para el Estado de Hidalgo;
      </a> donde se
      menciona que se deberá garantizar el acceso del personal de este
      Organismo, para poder registrar su consumo real de manera mensual y evitar
      así, una facturación de consumo presuntivo que pueda afectar su economía.
    </>
  );
}

function ComponentH() {
  return (
    <>
      Derivado de las medidas de mejora continua en atención a personas usuarias
      y en los servicios que ofrece{" "}
      <a
        href="https://caasim.hidalgo.gob.mx"
        target="_blank"
        rel="noreferrer"
        className="caasim-link"
      >
        La Comisión de Agua y Alcantarillado de Sistemas Intermunicipales
      </a>{" "}
      y de que <strong>su toma se encuentra sin medidor instalado</strong> se hace de su
      conocimiento que de conformidad con el <a href="Articulos"
        className="articulos-link"
        target="_blank"
        rel="noreferrer">
        artículos 115 de la Ley Estatal de
        Agua y Alcantarillado para el Estado de Hidalgo;
      </a> <strong>
        se instalará un medidor
        nuevo a su toma sin costo, sin embargo, si derivado del análisis del
        historial de su cuenta se determina que no es procedente instalar el
        medidor “sin costo”,
      </strong> será necesario acudir a la{" "}
      <a
        href="https://clausa.app.carto.com/map/377e629f-a9b2-44ea-a1db-50940252ec36"
        target="_blank"
        rel="noreferrer"
        className="sucursal-link"
      >
        Sucursal CAASIM
      </a>{" "}
      mas cercana a su domicilio en el área de Atención a Usuarios <strong>
        para realizar
        el trámite correspondiente;
      </strong> Lo anterior para poder registrar su consumo
      real de manera mensual y evitar así, una facturación de consumo presuntivo
      o la imposición de multas que puedan afectar su economía.
    </>
  );
}

function ComponentI01() {
  return (
    <>
      Derivado de las medidas de mejora continua en atención a personas usuarias
      y en los servicios que ofrece{" "}
      <a
        href="https://caasim.hidalgo.gob.mx"
        target="_blank"
        rel="noreferrer"
        className="caasim-link"
      >
        La Comisión de Agua y Alcantarillado de Sistemas Intermunicipales
      </a>
      , se le informa que <strong>
        no se ha registrado lectura debido a que hay perros
        cerca del medidor que impiden el acceso,
      </strong> por lo que es necesario tome las
      medidas necesarias para que el personal de este Organismo pueda realizar
      el registro de consumos sin riesgo a su integridad física. Cabe señalar
      que es responsabilidad de la persona usuaria sobre la toma y el acceso al
      medidor, sin limitar la propiedad de los perros que en su caso se
      encuentren en situación calle. Lo anterior es necesario para poder
      registrar su consumo real de manera mensual y evitar, así, una facturación
      de consumo presuntivo o la imposición de multas que puedan afectar su
      economía.
    </>
  );
}

function ComponentI02() {
  return (
    <>
      Derivado de las medidas de mejora continua en atención a personas usuarias
      y en los servicios que ofrece{" "}
      <a
        href="https://caasim.hidalgo.gob.mx"
        target="_blank"
        rel="noreferrer"
        className="caasim-link"
      >
        La Comisión de Agua y Alcantarillado de Sistemas Intermunicipales
      </a>
      , se le informa que <strong>
        no se ha registrado lectura debido a un obstáculo
        físico que impide el acceso a su medidor,
      </strong> por lo que es necesario tome las
      medidas necesarias para que retire dicho obstáculo y el personal de este
      Organismo pueda realizar el registro de consumo de manera mensual y
      evitar, así, una facturación de consumo presuntivo o la imposición de
      multas que puedan afectar su economía.
    </>
  );
}

function ComponentI03() {
  return (
    <>
      Si el recibo mensual <strong>no está siendo entregado en su domicilio,</strong> se le
      sugiere acudir a la{" "}
      <a
        href="https://clausa.app.carto.com/map/377e629f-a9b2-44ea-a1db-50940252ec36"
        target="_blank"
        rel="noreferrer"
        className="sucursal-link"
      >
        Sucursal CAASIM
      </a>{" "}
      mas cercana a su domicilio, en el área de Atención a Usuarios de este
      Organismo y <strong>
        en caso de que se le informe que se ha registrado la
        inconsistencia I03
      </strong> (corresponde a otra ruta), es necesario que proporcione
      mayores datos de ubicación de su domicilio.
      <div className="text-center pt-3">
        <p className="mb-0">Puede tambien visitar el sitio</p>
        <a
          href="https://servicioscaasim.hidalgo.gob.mx/"
          target="_blank"
          rel="noreferrer"
          className="servicios-link"
        >
          https://servicioscaasim.hidalgo.gob.mx
        </a>
        <p>
          <strong>
            donde podrá descargar su recibo de cobro de manera inmediata y si es
            de su conveniencia realizar su pago correspondiente.
          </strong>
        </p>
      </div>
    </>
  );
}

function ComponentK() {
  return (
    <>
      Inconsistencia derivada debido a que su toma se encuentra <strong>
        sin medidor
        instalado y con el servicio suspendido.
      </strong> Se le hace una cordial invitación
      para acudir a la{" "}
      <a
        href="https://clausa.app.carto.com/map/377e629f-a9b2-44ea-a1db-50940252ec36"
        target="_blank"
        rel="noreferrer"
        className="sucursal-link"
      >
        Sucursal CAASIM
      </a>{" "}
      mas cercana a su domicilio en el área de Atención a Usuarios de este
      Organismo para regularizar su adeudo y evitar cargos extras. <strong>
        Es necesario
        señalar que, si el contrato se encuentra activo, mensualmente se factura
        consumo mínimo, el cual se acumula a su adeudo.
      </strong>
    </>
  );
}
