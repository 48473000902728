import {
  convocatoria001,
  convocatoria002,
  convocatoria003,
  n1,
  n2,
  n3,
  n4,
  n5,
  n6,
  convocatoriaLOP001,
  lop1,
  convocatoria004,
  n7,
  convocatoria005,
  n8,
  n9,
  n10,
  n11,
  convocatoria006,
  n12,
  n13,
  n14,
  n15,
  n16,
  n17,
  n18,
  n19,
  convocatoria007,
  n20,
  convocatoriaMultiple002,
  lop2,
  lop3,
  lop4,
  convocatoria03Obra,
  lop5,
  convocatoria008,
  n22,
  n24,
  n25,
  convocatoria009,
  n26,
  n21,
  convocatoria010,
  n27,
  convocatoria04Obra,
  lop6,
  convocatoria011,
  n28,
  n29,
  n30,
  convocatoriaMultiple005,
  lop7,
  lop8,
  convocatoria012,
  n31,
  convocatoria06Obra,
  lop9,
  convocatoria013,
  n32,
  n33,
} from "../../json/anioLicitaciones/2024/anioLicitacion2024";

export const obtenerDatosSeleccionados = (selectedItemIndex) => {
  let datosSeleccionados = [];

  switch (selectedItemIndex) {
    case "Convocatoria 01-2024":
      datosSeleccionados = convocatoria001;
      break;
    case "Convocatoria 02-2024":
      datosSeleccionados = convocatoria002;
      break;
    case "Convocatoria 03-2024":
      datosSeleccionados = convocatoria003;
      break;
    case "EA-913005999-N1-2024":
      datosSeleccionados = n1;
      break;
    case "EA-913005999-N2-2024":
      datosSeleccionados = n2;
      break;
    case "EA-913005999-N3-2024":
      datosSeleccionados = n3;
      break;
    case "EA-913005999-N4-2024":
      datosSeleccionados = n4;
      break;
    case "EA-913005999-N5-2024":
      datosSeleccionados = n5;
      break;
    case "EA-913005999-N6-2024":
      datosSeleccionados = n6;
      break;
    case "Convocatoria 01-2024 OBRA":
      datosSeleccionados = convocatoriaLOP001;
      break;
    case "CAASIM-HGO-L.O.P.-01-2024":
      datosSeleccionados = lop1;
      break;
    case "Convocatoria 04-2024":
      datosSeleccionados = convocatoria004;
      break;
    case "EA-913005999-N7-2024":
      datosSeleccionados = n7;
      break;
    case "Convocatoria 05-2024":
      datosSeleccionados = convocatoria005;
      break;
    case "EA-913005999-N8-2024":
      datosSeleccionados = n8;
      break;
    case "EA-913005999-N9-2024":
      datosSeleccionados = n9;
      break;
    case "EA-913005999-N10-2024":
      datosSeleccionados = n10;
      break;
    case "EA-913005999-N11-2024":
      datosSeleccionados = n11;
      break;
    case "Convocatoria 06-2024":
      datosSeleccionados = convocatoria006;
      break;
    case "EA-913005999-N12-2024":
      datosSeleccionados = n12;
      break;
    case "EA-913005999-N13-2024":
      datosSeleccionados = n13;
      break;
    case "EA-913005999-N14-2024":
      datosSeleccionados = n14;
      break;
    case "EA-913005999-N15-2024":
      datosSeleccionados = n15;
      break;
    case "EA-913005999-N16-2024":
      datosSeleccionados = n16;
      break;
    case "EA-913005999-N17-2024":
      datosSeleccionados = n17;
      break;
    case "EA-913005999-N18-2024":
      datosSeleccionados = n18;
      break;
    case "EA-913005999-N19-2024":
      datosSeleccionados = n19;
      break;
    case "Convocatoria 07-2024":
      datosSeleccionados = convocatoria007;
      break;
    case "EA-913005999-N20-2024":
      datosSeleccionados = n20;
      break;
    case "Convocatoria Multiple 02-2024":
      datosSeleccionados = convocatoriaMultiple002;
      break;
    case "CAASIM-HGO-L.O.P.-02-2024":
      datosSeleccionados = lop2;
      break;
    case "CAASIM-HGO-L.O.P.-03-2024":
      datosSeleccionados = lop3;
      break;
    case "CAASIM-HGO-L.O.P.-04-2024":
      datosSeleccionados = lop4;
      break;
    case "Convocatoria 03-2024 OBRA":
      datosSeleccionados = convocatoria03Obra;
      break;
    case "CAASIM-HGO-L.O.P.-05-2024":
      datosSeleccionados = lop5;
      break;
    case "Convocatoria 08-2024":
      datosSeleccionados = convocatoria008;
      break;
    case "EA-913005999-N22-2024":
      datosSeleccionados = n22;
      break;
    case "EA-913005999-N24-2024":
      datosSeleccionados = n24;
      break;
    case "EA-913005999-N25-2024":
      datosSeleccionados = n25;
      break;
    case "Convocatoria 09-2024":
      datosSeleccionados = convocatoria009;
      break;
    case "EA-913005999-N26-2024":
      datosSeleccionados = n26;
      break;
    case "EA-913005999-N21-2024":
      datosSeleccionados = n21;
      break;
    case "Convocatoria 10-2024":
      datosSeleccionados = convocatoria010;
      break;
    case "EA-913005999-N27-2024":
      datosSeleccionados = n27;
      break;
    case "Convocatoria 04-2024 OBRA":
      datosSeleccionados = convocatoria04Obra;
      break;
    case "CAASIM-HGO-L.O.P.-06-2024":
      datosSeleccionados = lop6;
      break;
    case "Convocatoria 11-2024":
      datosSeleccionados = convocatoria011;
      break;
    case "EA-913005999-N28-2024":
      datosSeleccionados = n28;
      break;
    case "EA-913005999-N29-2024":
      datosSeleccionados = n29;
      break;
    case "EA-913005999-N30-2024":
      datosSeleccionados = n30;
      break;
    case "Convocatoria Multiple 05-2024":
      datosSeleccionados = convocatoriaMultiple005;
      break;
    case "CAASIM-HGO-L.O.P.-07-2024":
      datosSeleccionados = lop7;
      break;
    case "CAASIM-HGO-L.O.P.-08-2024":
      datosSeleccionados = lop8;
      break;
    case "Convocatoria 12-2024":
      datosSeleccionados = convocatoria012;
      break;
    case "EA-913005999-N31-2024":
      datosSeleccionados = n31;
      break;
    case "Convocatoria 06-2024 OBRA":
      datosSeleccionados = convocatoria06Obra;
      break;
    case "CAASIM-HGO-L.O.P.-09-2024":
      datosSeleccionados = lop9;
      break;
    case "Convocatoria 13-2024":
      datosSeleccionados = convocatoria013;
      break;
    case "EA-913005999-N32-2024":
      datosSeleccionados = n32;
      break;
    case "EA-913005999-N33-2024":
      datosSeleccionados = n33;
      break;
    default:
      datosSeleccionados = [];
  }

  return datosSeleccionados;
};
