import React from "react";
import Card from "../Card/Card";

const data = [
  {
    year: 2024,
    url: "/CAASIM/programa-anual-de-adquisiciones/PAAAS TRANSPARENCIA 2024.pdf",
  },
  {
    year: 2023,
    url: "/CAASIM/programa-anual-de-adquisiciones/PAAAS TRANSPARENCIA 2023.pdf",
  },
  {
    year: 2022,
    url: "/CAASIM/programa-anual-de-adquisiciones/PAAAS TRANSPARENCIA 2022.pdf",
  },
  {
    year: 2021,
    url: "/CAASIM/programa-anual-de-adquisiciones/PAAAS TRANSPARENCIA 2021.pdf",
  },
  {
    year: 2020,
    url: "/CAASIM/programa-anual-de-adquisiciones/PAAAS TRANSPARENCIA 2020.pdf",
  },
  {
    year: 2019,
    url: "/CAASIM/programa-anual-de-adquisiciones/PAAAS TRANSPARENCIA 2019.pdf",
  },
];

function ProgramasAnualesDeAdquisiciones() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">PROGRAMAS ANUALES DE ADQUISICIONES</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
        {data.map((programa, index) => (
          <Card
            key={index}
            document="Programa Anual de Adquisiciones"
            href={programa.url}
            year={programa.year}
            type={"PDF"}
          />
        ))}
        {/* <div className="col-md-3 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2023</h5>
              <div className="card_trimestre">
                <p>
                  Programa Anual de Adquisiciones
                  <span className="badge bg-secondary">
                    <a
                      href="/CAASIM/programa-anual-de-adquisiciones/PAAAS TRANSPARENCIA 2023.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      PDF
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2022</h5>
              <div className="card_trimestre">
                <p>
                  Programa Anual de Adquisiciones
                  <span className="badge bg-secondary">
                    <a
                      href="/CAASIM/programa-anual-de-adquisiciones/PAAAS TRANSPARENCIA 2022.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      PDF
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2021</h5>
              <div className="card_trimestre">
                <p>
                  Programa Anual de Adquisiciones
                  <span className="badge bg-secondary">
                    <a
                      href="/CAASIM/programa-anual-de-adquisiciones/PAAAS TRANSPARENCIA 2021.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      PDF
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2020</h5>
              <div className="card_trimestre">
                <p>
                  Programa Anual de Adquisiciones
                  <span className="badge bg-secondary">
                    <a
                      href="/CAASIM/programa-anual-de-adquisiciones/PAAAS TRANSPARENCIA 2020.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      PDF
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          <div className="card_container_historicos">
            <div className="card_anio">
              <h5>2019</h5>
              <div className="card_trimestre">
                <p>
                  Programa Anual de Adquisiciones
                  <span className="badge bg-secondary">
                    <a
                      href="/CAASIM/programa-anual-de-adquisiciones/PAAAS TRANSPARENCIA 2019.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      PDF
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default ProgramasAnualesDeAdquisiciones;
