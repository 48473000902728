import React from "react";
import "./municipiosYSucursales.css";
import Card from "../Card/Card";

// Extracted card component
// const Card = ({ title, subtitle, href, linkText }) => (
//   <div className="col-md-3 col-sm-6 col-12">
//     <div className="card_container_historicos">
//       <div className="card_anio">
//         <h5>{title}</h5>
//         <div className="card_trimestre">
//           <p className="d-flex align-items-center m-0">
//             {subtitle}
//             <span className="badge bg-secondary">
//               <a href={href} target="_blank" rel="noreferrer" aria-label={`Link to ${linkText}`}>
//                 {linkText}
//               </a>
//             </span>
//           </p>
//         </div>
//       </div>
//     </div>
//   </div>
// );

function MunicipiosYSucursales() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">MUNICIPIOS Y SUCURSALES ATENDIDOS POR CAASIM</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">  {/* fixed "className" */}
        <Card
          document="Listado"
          title="Municipios y Sucursales"
          href="/CAASIM/municipios-y-sucursales/Mpios y Sucursales CAASIM.pdf"
          type="PDF"
        />
        <Card
          document="Ubicación"
          title="Municipios y Sucursales"
          href="https://clausa.app.carto.com/map/377e629f-a9b2-44ea-a1db-50940252ec36"
          type="MAPA"
        />
        <Card
          document="Plataforma"
          title="Servicios en Línea &nbsp;"
          href="/CAASIM/municipios-y-sucursales/PROMOCIONAL SERVICIOS CAASIM.pdf"
          type="PDF"
        />
      </div>
    </div>
  );
}

export default MunicipiosYSucursales;
