import React from "react";
import "./cardLicitaciones.css";
import { obtenerDatosSeleccionados } from "../../methods/2024/methodsLicitaciones2024";

const CardLicitaciones = ({ selectedItemIndex }) => {
  const datosSeleccionados = obtenerDatosSeleccionados(selectedItemIndex);

  return (
    <div id="cardLicitaciones" className="container">
      <div className="row card-content-anio">
        {datosSeleccionados.map((item, index) => (
          <div key={index} className="col-sm-6 col-12">
            <div className="card-body card">
              <h5>{item.nombre}</h5>
              {item.documentos.map((documento, index2) => (
                <div key={index2} className="card_trimestre">
                  <p>
                    {documento.nombre}
                    {documento.contenido.map((contenido, index) => (
                      <div key={index}>
                        {contenido.excel && (
                          <span className="badge bg-secondary span-excel">
                            <a
                              href={contenido.excel}
                              target="_blank"
                              rel="noreferrer"
                            >
                              XLSX
                            </a>
                          </span>
                        )}
                        {contenido.pdf && (
                          <span className="badge bg-secondary span-pdf">
                            <a
                              href={contenido.pdf}
                              target="_blank"
                              rel="noreferrer"
                            >
                              PDF
                            </a>
                          </span>
                        )}
                        {contenido.word && (
                          <span className="badge bg-secondary span-word">
                            <a
                              href={contenido.word}
                              target="_blank"
                              rel="noreferrer"
                            >
                              DOCX
                            </a>
                          </span>
                        )}
                        {contenido.jpg && (
                          <span className="badge bg-secondary span-jpg">
                            <a
                              href={contenido.jpg}
                              target="_blank"
                              rel="noreferrer"
                            >
                              JPG
                            </a>
                          </span>
                        )}
                        {contenido.new && (
                          <span className="badge bg-secondary span-new">
                            NUEVO
                          </span>
                        )}
                      </div>
                    ))}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardLicitaciones;
