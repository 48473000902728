import React from "react";

export default function AvisoDePrivacidad() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">Aviso de Privacidad</h3>
        <hr className="hr-gob" />
      </div>
      <div>
        <p>
          Propósito por el cual se recaban sus datos personales y protección de
          los mismos
        </p>
        <p>
          <strong>
            La Comisión de Agua y Alcantarillado de Sistemas Intermunicipales
            C.A.A.S.I.M.
          </strong>{" "}
          ubicada en Avenida Industrial La Paz Número 200 Colonia Industrial La
          Paz, C.P. 42092 Pachuca de Soto, Hidalgo es la responsable del uso y
          protección de sus datos personales con fundamento en el Artículo 67 de
          la Ley de Transparencia y Acceso a la Información Pública para el
          Estado de Hidalgo así como a la Ley de Protección de Datos Personales
          en Posesión de Sujetos Obligados para el Estado de Hidalgo en sus
          artículos 1°, 3° fracciones I y XXX, 34, 35 y 39 informándole lo
          siguiente:
        </p>
        <ul>
          <li>
            Generar la facturación, en cumplimiento con las obligaciones
            fiscales{" "}
            <strong>
              Artículo 29-A del Código Fiscal de la Federación y 36 Fracción VI
              del Estatuto Orgánico
            </strong>
          </li>
        </ul>
        <p>
          <strong>
            No existen finalidades ni transferencias que requieran su
            consentimiento.
          </strong>{" "}
          Para el caso del ejercicio de los derechos ARCO el medio es a través
          de los datos de contacto de la Unidad de Transparencia del Poder
          Ejecutivo.
        </p>
        <p>
          Le informamos que si usted no manifiesta su negativa para llevar a
          cabo el tratamiento descrito en los apartados anteriores, entenderemos
          que ha otorgado su consentimiento para hacerlo. salvo lo establecido
          por los artículos 7 Fracciones I, II y IV, 19 y 98 por causas de
          excepción previstas en la citada ley de protección de datos
          personales.
        </p>
        <p>
          Para llevar a cabo la finalidad descrita en el presente aviso de
          privacidad, utilizaremos los siguientes{" "}
          <strong>datos personales:</strong>
        </p>
        <ol>
          <li>Nombre Completo o Razón Social</li>
          <li>
            Domicilio fiscal {'('}Calle, No. exterior, No. interior, Colonia, Código
            Postal, Localidad y Municipio{')'}
          </li>
          <li>R.F.C.</li>
          <li>Correo electrónico</li>
          <li>Número telefónico de contacto {'('}celular o fijo{')'}</li>
        </ol>
        <p>
          <strong>No</strong> utilizaremos datos personales sensibles.
        </p>
        <p>
          <strong>Cláusula de Transferencia:</strong> Se le informa que sus
          datos personales serán compartidos con la siguiente institución:
        </p>
        <ul>
          <li>
            Secretaria de Hacienda y Crédito Público para la finalidad que se
            indica, de conformidad a lo siguiente:{" "}
            <strong>
              Artículo 29 del Código Fiscal de la Federación Párrafo Primero y
              Segundo Fracción Cuarta y Penúltima
            </strong>
          </li>
        </ul>
        <p>
          La ultima actualización y/o modificación del presente aviso es la
          indicada al final del mismo, situación que también podrá informarse
          directamente en las oficinas de esta área responsable de la protección
          de sus datos o a través de la página web institucional.
        </p>
        <p>
          Usted tiene derecho a conocer qué datos personales tenemos de usted,
          para qué los utilizamos y las condiciones del uso que les damos
          <strong>{'('}acceso{')'}</strong>. Asimismo, es su derecho solicitar la
          corrección de su información personal en caso de que esté
          desactualizada, sea inexacta o incompleta{" "}
          <strong>{'('}rectificación{')'}</strong>; que la eliminemos de nuestros
          registros o bases de datos cuando considere que la misma no está
          siendo utilizada conforme a los principios, deberes y obligaciones
          previstas en la normativa <strong>{'('}cancelación{')'}</strong>; así como
          oponerse al uso de sus datos personales para fines específicos{" "}
          <strong>{'('}oposición{')'}</strong>. Estos derechos se conocen como derechos
          ARCO.
        </p>

        <p>
          Los datos de contacto de la Unidad de Transparencia del Poder
          Ejecutivo, quién gestionará las solicitudes para el ejercicio de
          derechos ARCO, asimismo auxiliará y orientará respecto al ejercicio
          del derecho a la protección de datos personales, son los siguientes:
          Carretera a la Estanzuela S/N, San Agustín Tlaxiaca, Hgo. C.P. 42162. Teléfonos {'('}771{')'}
          71-8-62-15 o 79-7-52-76, E-mail:{" "}
          <a href="mailto:dgtg.transparencia@hidalgo.gob.mx">
            uipg@hidalgo.gob.mx
          </a>
        </p>
      </div>
    </div>
  );
}
