import React, { useState, useEffect } from "react";
import "./indicadores.css";

export default function Indicadores() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Define an asynchronous function inside the useEffect
    const fetchData = async () => {
      try {
        const response = await fetch(
          "http://200.79.182.114/json/caasimweb/servicerest.php"
        );

        // Check if the response is successful
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("There was an error fetching the data", error);
      }
    };

    // Call the asynchronous function
    fetchData();
  }, []); // The empty array ensures that this effect runs only once, similar to componentDidMount

  function customCapitalize(string) {
    return string
      .split(" ") // Split the string into words
      .map((word) =>
        word.toLowerCase() === "caasim"
          ? "CAASIM"
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" "); // Join the words back together
  }

  return (
    <div>
      <div className="notes_title">
        <h3 className="title">INDICADORES</h3>
        <hr className="hr-gob" />
      </div>
      <div className="myContainer">
        {data.map((item, index) => {
          // Check if nombre is "Familias apoyadas", if yes then return null, which means nothing will be rendered
          if (item.nombre === "Familias apoyadas") {
            return null;
          }

          return (
            <React.Fragment key={index}>
              <div className="myColumn">{customCapitalize(item.nombre)}</div>
              <div className="myColumn h5 mb-0" role="alert">
                <strong>{item.total.toLocaleString()}</strong>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
