import React, { useEffect, useState } from 'react';
import './carousel.css';
import Search from '../Search/Search';
import { carouselInicioDesktop, carouselInicioMobile } from '../../json/carousel';

const Carousel = ({ search, name }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [images, setImages] = useState(isMobile ? carouselInicioMobile : carouselInicioDesktop);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setImages(isMobile ? carouselInicioMobile : carouselInicioDesktop);
  }, [isMobile]);

  return (
      <div id={name} className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {
            images.map(( item,index )=>(
              <div key={ index } className={ index >= 1 ? "carousel-item" : "carousel-item active" }>
                <a href={ item.url } target="_blank" rel='noreferrer'>
                  <div className="img-carousel d-block w-100" style={{ backgroundImage: `url("${ item.imagen }")` }} aria-label={item.description}>
                  <span className="visually-hidden">{item.description}</span>
                  </div>
                </a>
              </div>
            ))
          }
          {
            search ? <Search /> : null
          }
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target={"#"+ name} data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target={"#"+ name} data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
  )
}

export default Carousel;