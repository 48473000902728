import React from 'react';
import './waterDrop.css';  // Import the CSS file

const WaterDrop = () => {
  const handleWhatsAppClick = () => {
    // Replace the URL with the WhatsApp bot link
    window.open('https://api.whatsapp.com/send?phone=5217712422677&text=Hola', '_blank');
  };

  return (
    <div className="container-water-drop">
      {/* Water Drop Robot Image */}
      <div className='container-water-drop-inside'>
        <img
          src="/assets/imgs/waterdrop/Gotin-Personaje.png"
          alt="Water Drop Robot"
          className="robotImage"
        />
        {/* WhatsApp Logo */}
        <img
          src="/assets/imgs/waterdrop/Gotin-LogoWhatsapp.png"
          alt="WhatsApp"
          className="whatsappImage"
          onClick={handleWhatsAppClick}
        />
      </div>
    </div>
  );
};

export default WaterDrop;
