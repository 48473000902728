import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import './header.css';
import { links } from '../../json/headerLinks';

const Header = () => {
    const [overlay, setOverlay] = useState(false);

    const hideMenu = () => {
        const div = document.getElementById('navbarNav');
        div.classList.remove('show');
        setOverlay(false);
    };

    return (
        <>
            {
                overlay && 
                <Overlay onAddOverlay={setOverlay}/>
            }
            <div id='header'>
                <nav className="navbar navbar-expand-lg navbar-light bg-gob">
                    <div className="container-fluid">
                        <Link className="nav-link active" aria-current="page" to="/">
                            {
                                isMobile ? 
                                <img className='logo-menu' alt='Logo del Gobierno del Estado de Hidalgo' src='/assets/logos/caasim-logo-header-veda.png'/> 
                                : 
                                <span>Comisión de Agua y Alcantarillado de Sistemas Intermunicipales</span>
                            }
                        </Link>
                        <button 
                            className="navbar-toggler" 
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#navbarNav" 
                            aria-controls="navbarNav" 
                            aria-expanded="false" 
                            aria-label="Toggle navigation" 
                            onClick={() => {setOverlay(true)}}
                            style={{ color: "white" }}
                        >
                            MENÚ
                        </button>
                        <div className="collapse navbar-collapse second-navbar-gob" id="navbarNav">
                            <ul id='menu-list' className="navbar-nav text-center" onClick={hideMenu}>
                                {
                                    links.map((item, index) => (
                                        <li key={index} className="nav-item">
                                            <Link to={item.link} className="nav-link">{item.nombre}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

const Overlay = ({onAddOverlay}) => {
    return (
        <div className='overlay-menu' data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" onClick={() => {onAddOverlay((overlay) => !overlay)}}></div>
    )
}

export default Header;
