import React from "react";
import { years } from "../../json/gastoFederalizado";
import "./gastofederalizado.css";

const GastoFederalizado = () => {
  const [activeYear, setActiveYear] = React.useState(null);

  const toggleYear = (year) => {
    if (activeYear === year) {
      return setActiveYear(null);
    }
    setActiveYear(year);
  };
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title text-uppercase">
          Informe de Gasto Federalizado SFU
        </h3>
        <hr className="hr-gob" />
      </div>

      {/* Accordion */}
      <div className="accordion accordion-w m-auto">
        {years.map(({ year, content }) => (
          <div key={year} className="">
            <h2 className="mb-1">
              <button
                className="accordion-btn font-weight-bold d-flex justify-content-between align-items-center w-100 rounded"
                onClick={() => toggleYear(year)}
              >
                <span>{year}</span>
                <i
                  className={`fa ${
                    activeYear === year ? "fa-angle-up" : "fa-angle-down"
                  }`}
                ></i>
              </button>
            </h2>
            {activeYear === year && (
              <div className="accordion-content mb-1">
                {content.map((item, index) => (
                  <div key={index} className="accordion-item d-flex justify-content-between ">
                    <span className="">{item.trimester}</span>
                    <a
                    className="font-weight-bold"
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PDF
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GastoFederalizado;
