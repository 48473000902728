import {
  inv01,
} from "../../json/anioInvitaciones/2024/anioInvitacion2024";

export const obtenerDatosSeleccionados = (selectedItemIndex) => {
  let datosSeleccionados = [];

  switch (selectedItemIndex) {
    case "CAASIM-HGO-INV3-OP-01-2024":
      datosSeleccionados = inv01;
      break;
    default:
      datosSeleccionados = [];
  }

  return datosSeleccionados;
};
