import "./contraloriaSocial.css";
import Card from "../Card/Card";

function ContraloriaSocial() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">CONTRALORIA SOCIAL</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
        <Card
          document="Información de los Ejercicios"
          href="/"
          title="Construcción del Colector Marginal Sanitario Azoyatla-Viaducto
                Rojo Gomez Etapa 2/10"
          year={2020}
        />
      </div>
    </div>
  );
}

export default ContraloriaSocial;
