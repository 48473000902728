export const carouselInicioDesktop = [
  {
    id: "image1",
    imagen: "/assets/imgs/carousel-imgs/desktop/img_1.jpg",
    description:
      "Trabajadores de la CAASIM cavando para arreglar una fuga de agua",
  },
  {
    id: "image2",
    imagen: "/assets/imgs/carousel-imgs/desktop/transparencia2024.png",
    description:
      "El Director de CAASIM escuchando a una persona de la tercera edad sus inquietudes sobre el uso del agua",
  },
  {
    id: "image3",
    imagen: "/assets/imgs/carousel-imgs/desktop/img_3.jpg",
    description: "Una bomba funcionando en un pozo de CAASIM",
  },
];

export const carouselInicioMobile = [
  {
    id: "image1",
    imagen: "/assets/imgs/carousel-imgs/mobile/img_1.jpg",
    description:
      "Trabajadores de la CAASIM cavando para arreglar una fuga de agua",
  },
  {
    id: "image2",
    imagen: "/assets/imgs/carousel-imgs/mobile/transparencia2024.png",
    description:
      "El Director de CAASIM escuchando a una persona de la tercera edad sus inquietudes sobre el uso del agua",
  },
  {
    id: "image3",
    imagen: "/assets/imgs/carousel-imgs/mobile/img_3.jpg",
    description: "Una bomba funcionando en un pozo de CAASIM",
  },
];
